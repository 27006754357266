import * as React from "react";
import Box from "@mui/material/Box";
import ItemCard from "../../component/card/productCard";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import Modal from "../../component/modal";
import Cash from "./onlinePopup";
import Ofline from "./oflinePopup";
import { useSelector } from "react-redux";
import { BillingReturn } from "../../Interface/BillingReturnInterface";
import IncrementFunction from "../../utility/IncrementFunction";
import DecrementFunction from "../../utility/DecrementFunction";
import { useBillingStyles } from "../pagesUtils/useBillingStyles";
import ReturnBarcodeInput from "../../component/inputs/ReturnBarcodeInput";

export default function Index() {
  const classes = useBillingStyles();
  const [barcode, setBarcode] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [isOnline, setIsonline] = React.useState(false);
  const { products } = useSelector((state: any) => state.returnReducer);
  const [addedProduct, setAddedProduct] = React.useState<BillingReturn[]>([]);
  const [grandTotal, setGrandTotal] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [cashReceive, setCashReceive] = React.useState();
  const [cashReturn, setCashReturn] = React.useState<string | number>();
  const [discounPersentage, setDiscountPercentage] = React.useState<number>(0);
  const [discounAmount, setDiscountAmount] = React.useState<number>(0);
  const [name,setName] = React.useState("")

  const handleChangeBarcode = (e: any) => {
    const val = e.target.value;
    setBarcode(e.target.value);
    let filteredNewArray: any = [];
    if (addedProduct?.length) {
      const isAlreadyAdded = addedProduct.filter(
        (item: any) => item.invoiceNo.toLowerCase() !== val
      );
      if (isAlreadyAdded.length) return;
    }
    // eslint-disable-next-line
    const filteredProduct = products.filter((a: any) =>
      // eslint-disable-next-line
      a.actualBillingSent.filter((item: any) => {
        // eslint-disable-next-line
        if (
          a?.stateInvoiceNo.includes(e.target.value) === true ||
          a?.stateInvoiceNo.toLowerCase().replace(/ +/g, "") ===
            e.target.value.toLowerCase().replace(/ +/g, "") ||
          a?.stateInvoiceNo
            .toLowerCase()
            .replace(/ +/g, "")
            ?.includes(e.target.value) === true
        ) {
          const newObj = {
            id: a.id,
            name: item.product.name,
            quantity: 0,
            price: +item?.sellingPrice,
            totalQuantity: item.quantity,
            itemId: item.product.id,
            invoiceNo: a?.stateInvoiceNo,
            weight: item?.product?.description,
          };
          filteredNewArray = [...filteredNewArray, newObj];
          // filteredNewArray.push(newObj);
          return newObj;
        }
        // return filteredNewArray
      })
    );
    if (!filteredProduct.length) {
      // setAddedProduct([]);
      return;
    }
    setAddedProduct([...addedProduct, ...filteredNewArray]);
    // }
  };

  React.useEffect(() => {
    let total: number = 0;
    filtered.forEach((singleTotal: BillingReturn) => {
      total = total + +singleTotal.price * singleTotal.quantity;
    });
    if (discounAmount) {
      setGrandTotal(total - (total * discounPersentage) / 100);
      setDiscountAmount((total * discounPersentage) / 100);
      // setGrandTotal(total - discounAmount);
    } else {
      setGrandTotal(total);
    }
    setTotal(total);
    // eslint-disable-next-line
  }, [addedProduct,products]);

  const handleChangeDiscountAmount = (e: any) => {
    const amount = e.target.value;
    if (amount > total) {
      setDiscountAmount(0);
      setDiscountPercentage(0);
      setGrandTotal(total);
    } else {
      setDiscountAmount(amount);
      setGrandTotal(total - amount);
      setDiscountPercentage((amount / total) * 100);
    }
  };

  const handleChangeDiscountPercentage = (e: any) => {
    const percentage = e.target.value;
    if (Number(percentage) >= 100) {
      setDiscountPercentage(100);
    } else {
      setDiscountPercentage(percentage);
      setGrandTotal(total - (total * percentage) / 100);
      setDiscountAmount((total * percentage) / 100);
    }
  };

  const handleChnageCashReceive = (e: any) => {
    const receive = e.target.value;
    setCashReceive(receive);
    setCashReturn(receive - grandTotal);
  };
  const handleChnageCashReturn = (e: any) => {
    const returnCash = e.target.value;
    setCashReturn(returnCash);
  };

  const arr = addedProduct;
  const ids = arr.map((o) => o.itemId);
  const filtered = arr.filter(({ itemId }, index) => !ids.includes(itemId, index + 1));
  var debug = filtered?.filter((x) => {
    return x?.invoiceNo.toLowerCase().replace(/ +/g, "") ===
    barcode.toLowerCase().replace(/ +/g, "") ;
  });
  

  React.useEffect(() => {
    let filteredNewArray: any = [];
    // eslint-disable-next-line
    const filteredProduct = products.filter((a: any) =>
      // eslint-disable-next-line
      a.actualBillingSent.filter((item: any) => {
        if (
          a?.stateInvoiceNo.includes(barcode) === true ||
          a?.stateInvoiceNo.toLowerCase().replace(/ +/g, "") ===
            barcode.toLowerCase().replace(/ +/g, "") ||
          a?.stateInvoiceNo
            .toLowerCase()
            .replace(/ +/g, "")
            ?.includes(barcode) === true
        ) {
          const newObj = {
            id: a.id,
            name: item.product.name,
            quantity: 0,
            price: +item?.sellingPrice,
            totalQuantity: item.quantity,
            itemId: item.product.id,
            invoiceNo: a?.stateInvoiceNo,
            weight: item?.product?.description,
          };
          filteredNewArray = [...filteredNewArray, newObj];
          // filteredNewArray.push(newObj);
          return newObj;
        }
        // return filteredNewArray
      })
    );
    if (!filteredProduct.length) {
      // setAddedProduct([]);
      return;
    }
    setAddedProduct([...addedProduct, ...filteredNewArray]);
    // }
    // eslint-disable-next-line
  }, [products,barcode]);

  return (
    <Box>
      <Box>
        <Box sx={{ width: "65%" }}>
          <ReturnBarcodeInput
            filtered={filtered}
            value={barcode}
            onChange={handleChangeBarcode}
            name={"Return"}
          />
        </Box>
        <Box sx={{ mt: 2, display: "flex", flexWrap: "wrap", width: "65%" }}>
          {barcode !== null && barcode !== "" ? (
            <>
              {debug?.map((card: BillingReturn) => (
                <ItemCard
                  key={card.index}
                  productName={card.name}
                  quantity={card.quantity}
                  price={card.price}
                  increaseQuantity={() =>
                    IncrementFunction(card.itemId, addedProduct, setAddedProduct)
                  }
                  decreaseQuantity={() =>
                    DecrementFunction(card.itemId, addedProduct, setAddedProduct)
                  }
                  weight={card.weight}
                  totalQuantity={card.totalQuantity}
                />
              ))}
            </>
          ) : (
            <></>
          )}
        </Box>
        {filtered.length && barcode !== null && barcode !== "" ? (
          <Paper
            sx={{
              width: 400,
              position: "fixed",
              top: 0,
              bottom: 0,
              right: 0,
              zIndex: 99,
              padding: 2,
            }}
          >
            <Box sx={{ position: "relative", height: "97vh" }}>
              {filtered.length &&
                // eslint-disable-next-line
                filtered?.map((item: BillingReturn) => {
                  if (item.quantity > 0) {
                    return (
                      <>
                        {item.quantity && (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography>
                              {`${item.name} ${item.price}x${item.quantity}`} :-
                            </Typography>
                            <Typography>
                              Rs {item.price * item.quantity}
                            </Typography>
                          </Box>
                        )}
                      </>
                    );
                  }
                })}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography component="div">Total:-</Typography>
                <Typography component="div">Rs {total}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2,
                  alignItems: "center",
                }}
              >
                <Typography>Discount Amount:-</Typography>
                <Typography component="div" className={classes.amount}>
                  <input
                    name="discountAmount"
                    type="text"
                    value={discounAmount}
                    placeholder="amount"
                    onChange={handleChangeDiscountAmount}
                  />
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2,
                  alignItems: "center",
                }}
              >
                <Typography>Discount Percent:-</Typography>
                <Typography component="div" className={classes.amount}>
                  <input
                    name="discountPercent"
                    type="text"
                    placeholder="%"
                    value={discounPersentage}
                    onChange={handleChangeDiscountPercentage}
                  />
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderTop: "2px solid black",
                  borderBottom: "2px solid black",
                  py: 1,
                  mt: 2,
                }}
              >
                <Typography variant="h4">Grand Total :-</Typography>
                <Typography component="div" className={classes.amount}>
                  {grandTotal}
                </Typography>{" "}
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Typography>Cash Received :</Typography>
                <Typography component="div" className={classes.amount}>
                  <input
                    type="text"
                    name="cashReceive"
                    onChange={handleChnageCashReceive}
                    value={cashReceive}
                  />
                </Typography>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Typography component="div">Return :</Typography>
                <Typography component="div" className={classes.amount}>
                  <input
                    type="text"
                    name="cashReturn"
                    onChange={handleChnageCashReturn}
                    value={cashReturn}
                  />
                </Typography>
              </Box>
              <Box
                sx={{ position: "absolute", bottom: 0, display: "flex", mt: 2,right:0 }}
              >
                {/* <Typography
                  className={classes.cashCollectMethod}
                  onClick={() => {
                    setIsonline(true);
                    setOpen(true);
                    setName("online")
                  }}
                >
                  Online
                </Typography>
                <Typography
                  className={classes.cashCollectMethod}
                  onClick={() => {
                    setOpen(true);
                    setName("card")
                  }}
                >
                  Card
                </Typography>
                <Typography
                  className={classes.cashCollectMethod}
                  onClick={() => {
                    setOpen(true);
                    setName("upi")
                  }}
                >
                  UPI
                </Typography> */}
                <Typography
                  className={classes.cashCollectMethod}
                  onClick={() => {
                    setOpen(true);
                    setName("cash")
                  }}
                >
                  Cash
                </Typography>
              </Box>
            </Box>
          </Paper>
        ) : (
          <></>
        )}
      </Box>
      <Modal
        header="Return"
        children={
          isOnline ? (
            <Cash
              addedProduct={filtered}
              orderId={barcode}
              grandTotal={grandTotal}
              setBarcode={setBarcode}
              setAddedProduct={setAddedProduct}
              setOpen={setOpen}
              openName={name}
            />
          ) : (
            <Ofline
              addedProduct={filtered}
              orderId={barcode}
              grandTotal={grandTotal}
              name={"Return"}
              setBarcode={setBarcode}
              setAddedProduct={setAddedProduct}
              setOpen={setOpen}
              openName={name}
            />
          )
        }
        handleClose={() => {
          setOpen(false);
          setIsonline(false);
        }}
        open={open}
      />
    </Box>
  );
}
