import * as React from "react";
import Box from "@mui/material/Box";
import { Divider,Button } from "@mui/material";
// import { QRCodeCanvas } from "qrcode.react";
import IconButton from "@mui/material/IconButton";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
// import ClearIcon from "@mui/icons-material/Clear";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Utils from "../../utility";
import {
  updateOrderStatus,
  // asignRunner,
  // deleteSingleProduct,
  updateSingleOrderStatus,
  zomatoUpdate,
} from "./action";
import { orderInvoice } from "../billing/action";
import axios from 'axios';

const Index = (props: any) => {
  const {
    id,
    orderDate,
    customerName,
    phoneNumber,
    orderAmountTotal,
    addressLine1,
    addressLine2,
    lat,
    runnerId,
    stateInvoiceNo,
    long,
    totalQuantity,
    orderStatus,
    products,
    token,
    deliveryMode,
    zomato
  } = props?.data;
  const { filterdOrder } = useSelector((state: any) => state.ordersReducer);
  const dispatch: any = useDispatch();
  const [rider, setRider] = React.useState<string>("");
  const [zomatoData, setZomatoData] = React.useState<any>()
  const [totalAmount, setToatalAmount] = React.useState<number>(0);
  const [totalItem, setTotalItem] = React.useState<number>(0);
  // const { runnerList } = useSelector((state: any) => state.ordersReducer);
  const [isLoading, setIsLoading] = React.useState(false);
  // const getStoreId = localStorage.getItem("storeId");
  const { storeId: getStoreId } = useSelector(
    (state: any) => state.commonReducer
  );

  const currentDate = new Date();
  const oneWeekAgo = new Date(currentDate);
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

  const formattedFromDate = oneWeekAgo.toISOString();
  const formattedToDate = currentDate.toISOString();


  const handleChangeQuantity = (e: any, i: number) => {
    let data = filterdOrder[props.index];
    let productData = data?.products[i];
    productData.quantity = e.target.value;
    dispatch({
      type: Utils.ActionName.ORDERS,
      payload: {
        filterdOrder: filterdOrder,
      },
    });

    let newCount = 0;
    let newTotal = 0;
    data?.products.forEach((element: any) => {
      newCount = newCount + +element.quantity;
      newTotal = +element.quantity * element.sellingPrice + newTotal;
    });
    setTotalItem(newCount);
    setToatalAmount(newTotal);
  };
  const productChangeStatus = (i: number, status: string) => {
    let data = filterdOrder[props.index];
    let productData = data?.products[i];
    productData.status = status;
    dispatch({
      type: Utils.ActionName.ORDERS,
      payload: {
        filterdOrder: filterdOrder,
      },
    });
    if (
      data?.products?.filter(
        (row: any) => row?.status === "packed" || row?.status === "reject"
      ).length === data?.products.length
    ) {
      dispatch(updateOrderStatus(id, "packed",getStoreId,formattedFromDate,formattedToDate));
    }
  };

  React.useEffect(() => {
    // if(zomato!== null && zomato?.internalOrderID !== undefined && Number(zomato?.externalOrderID)===id){
    //   setIsLoading(true);
    // const postData = {
    //   internal_order_id: zomato?.internalOrderID
    // };

    // const apiUrl = "https://uatservices.thenewshop.in/api/v1/zomato/track";

    // axios.post(apiUrl, postData)
    //   .then(response => {
    //     setIsLoading(false);
    //     if(response.data.status.code===500){
    //       console.error(response.data.errors)
    //       // Utils.showAlert(2, response.data.errors);
    //     }else{
    //      setZomatoData(response.data)
    //     }
    //   })
    //   .catch(error => {
    //     setIsLoading(false);
    //     console.error(error);
    //   });
    // }
    
    if (runnerId) {
      setRider(runnerId);
    }
     // eslint-disable-next-line
  }, [runnerId]);

  // const DeleteProduct = (id:number) =>{
  //   dispatch(deleteSingleProduct(id,getStoreId,formattedFromDate,formattedToDate))
  // }
  const trackZomato = (orderId,data) => {
    if(Number(data.externalOrderID)===orderId){
      setIsLoading(true);
    const postData = {
      internal_order_id: data?.internalOrderID
    };

    const apiUrl = "https://uatservices.thenewshop.in/api/v1/zomato/track";

    axios.post(apiUrl, postData)
      .then(response => {
        setIsLoading(false);
        if(response.data.status.code===500){
          Utils.showAlert(2, response.data.errors);
        }else{
           setZomatoData(response.data)
        }
      })
      .catch(error => {
        setIsLoading(false);
        console.error(error);
      });
    }
  }

  const handleClick = (data:any) => {
    let riderDataRes;
    if(data === "Zomato Rider"){
      riderDataRes = "zomato"
    }else if(data === "Store Rider"){
      riderDataRes = "store"
    }
      dispatch(zomatoUpdate(id,getStoreId,riderDataRes,formattedFromDate,formattedToDate))
   }

  return (
    <>
    {orderStatus!=="delivered" && orderStatus!=="cancelled" && token!=="" && (
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{ flex: 1, m: 1 }}
        >
          <p>
            <b>Order No</b>:{id}
          </p>
          <p>
            <b>Order Time</b>:{orderDate}
          </p>
          <p>
            <b>Customer Name</b>:{customerName}
          </p>
          <p>
            <b>Customer Phone</b>:{phoneNumber}
          </p>
          {deliveryMode!=="self" ?
          <p>
            <b>Customer Address</b>:{" "}
            {`${addressLine1},  ${addressLine2}`}
          </p> : (
              <p>
              <b>Customer Address</b>:{" "}
              Store Pickup
            </p>
          )}
           <p>
            <b>Delivery Mode</b>:{" "}{deliveryMode}
          </p>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box
          sx={{
            flex: 2,
            m: 1,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flex: 3 }}>
            {products?.length &&
              products?.map((item: any, i: number) => (
                <Box
                  sx={{ display: "flex", justifyContent: "space-between" }}
                  key={i}
                  style={item?.status === "packed" ? { paddingRight: 40 } : {}}
                >
                  <Box>
                    <b>{`(${item?.barcode})`}</b>
                    {item?.name} {`(${item.sellingPrice})`}
                  </Box>
                  <Box>
                  {/* {orderStatus !== "out for delivery" && (
                    <IconButton
                      aria-label="delete"
                      onClick={()=>DeleteProduct(item?.billingId)}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}  */}
                    <input
                      type="number"
                      value={item?.quantity}
                      disabled={
                        orderStatus !== "placed" ||
                        (orderStatus === "placed" && item.status === "packed")
                      }
                      style={{ width: 50, padding: 5 }}
                      onChange={(e: any) => handleChangeQuantity(e, i)}
                    />
                    {orderStatus !== "placed" ||
                    (orderStatus === "placed" && item.status === "packed") ? (
                      <></>
                    ) : (
                      <IconButton
                        aria-label="delete"
                        onClick={() => productChangeStatus(i, "packed")}
                      >
                        <CheckCircleOutlineIcon />
                      </IconButton>
                    )}
                  </Box>
                </Box>
              ))}
            <Box sx={{ my: 1 }}>
              Total Item:-{totalItem ? totalItem : totalQuantity}
            </Box>
            <Box sx={{ my: 1 }}>
              Total Amount:-{totalAmount ? totalAmount : orderAmountTotal}
            </Box>{" "}
            {/* <Button
              variant="outlined"
              sx={{ width: 200 }}
              disabled={orderStatus !== "placed"}
              onClick={() => props.setOpen(id)}
            >
              Add Item
            </Button> */}
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box sx={{ m: 1, flex: 3 }}>
            {zomato!==null && zomatoData!==undefined && deliveryMode==="zomato" && (
              <Box
          sx={{ flex: 1, m: 1 }}
        >
          <p>
            <b>Zomato Status</b>:{isLoading===true?"Loading....":zomatoData?.order_status}
          </p>
          <p>
            <b>Driver Name</b>:{isLoading===true?"Loading....":zomatoData?.driver_name}
          </p>
          <p>
            <b>Driver Phone Number</b>:{isLoading===true?"Loading....":zomatoData?.driver_phone}
          </p>
          <p>
            <b>Vechile Name</b>:{isLoading===true?"Loading....":zomatoData?.vehicle_name}
          </p>
          <p>
            <b>Vechile Number</b>:{" "}
             {isLoading===true?"Loading....":zomatoData?.vehicle_number}
          </p> 
          <p>
            <b>Expeted Time Arrival</b>:{" "}
           {isLoading===true?"Loading....":zomatoData?.Eta?.total_eta}
          </p> 
        </Box>
            )}
          </Box>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box sx={{ flex: 1, m: 1 }}>
          <Box
            style={
              orderStatus === "placed"
                ? { backgroundColor: "yellow" }
                : orderStatus === "packed"
                ? { backgroundColor: "lightblue" }
                : orderStatus === "out for delivery"
                ? { background: "lightgreen" }
                : orderStatus === "delivered"
                ? { background: "lightgreen" }
                : { background: "lightred" }
            }
            sx={{ p: 2, textAlign: "center" }}
          >
            <b>Status: {orderStatus}</b>
          </Box>
          <Box sx={{ my: 2 }}>
          <div>
            <h1>{isLoading}</h1>
  {[
    deliveryMode === "store" && (
      <Button
      variant="outlined"
      sx={{ width: "100%" }}
        onClick={() => handleClick("Zomato Rider")}
      >
       Assign to Zomato Rider
      </Button>
    ),
    deliveryMode === "zomato" && (
      <Button
      variant="outlined"
      sx={{ width: "100%" }}
        onClick={() => handleClick("Store Rider")}
      >
      Cancel Zomato and Assign Store Rider
      </Button>
    ),
  ]}
</div>
          </Box>
          {orderStatus === "packed" || deliveryMode === "self" ? (
  <Button
    variant="outlined"
    sx={{ width: "100%" }}
    onClick={() => {
        dispatch(updateSingleOrderStatus(id, "delivered",getStoreId,formattedFromDate,formattedToDate));
    }}
    disabled={orderStatus!=="packed"}
  >
   Mark as Delivered
   {/* {orderStatus !== "out for delivery" && orderStatus !== "packed" && deliveryMode !== "self" ? "Pick up" : "Mark as Delivered"}  */}
  </Button>
) : null}

          {zomato!==null && deliveryMode==="zomato" && (
          <Button
          variant="outlined"
          sx={{ width: "100%",marginTop: "20px" }}
          onClick={()=>trackZomato(id,zomato)}
        >
          Zomato Delivery Status
        </Button>
          )}
        <Button
              variant="outlined"
              sx={{ width: "100%", marginTop: "20px" }}
              onClick={() => dispatch(orderInvoice(stateInvoiceNo))}
            >
              Print Invoice
            </Button>
        </Box>
      </Box>
           )} 
      {orderStatus!=="delivered" && orderStatus!=="cancelled" && lat && token!=="" && (
        <Button
          variant="outlined"
          sx={{ width: "100%" }}
        >
          <a target="_blank"  rel="noreferrer" href={`https://maps.google.com/?q=${lat},${long}`}>
            Open map
          </a>
        </Button>
      )}
    </>
  );
};

export default Index;
