import Box from "@mui/material/Box";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import RtvBarcodeInput from "../../component/inputs/RtvBarcodeInput";
import { RootStateProduct } from "../../Interface/BillerIdInterface";
import { ProductInterface } from "../../Interface/ProductsInterface";
import RtvDiscardBarcode from "../../utility/RtvDiscardBarcode";
import { createRtvandDiscordStock } from "../discardProduct/action";
import AddedProduct from "../pagesUtils/AddedProduct";

export default function Index() {
  const dispatch: any = useDispatch();
  const [barcode, setBarcode] = React.useState<string>("");
  const { products } = useSelector((state: RootStateProduct) => state.productsReducer);
  const [addedProduct, setAddedProduct] = React.useState<ProductInterface[]>([]);
  const [isDisabled, setIsDisabled] = React.useState(false);
  // const getStoreId = localStorage.getItem("storeId")
  const { storeId: getStoreId } = useSelector(
    (state: any) => state.commonReducer
  );
  const filterProduct = addedProduct?.map((a:any)=>a.quantity>0&&a)

  const lastFinalProduct = filterProduct.filter(Boolean)

  const submitRtv = () =>{
    setIsDisabled(true);
    dispatch(createRtvandDiscordStock(lastFinalProduct,getStoreId,"false","Rtv",setAddedProduct,setBarcode,setIsDisabled));
  }

  React.useEffect(()=>{
    let filteredNewArray: any = [];
    if (barcode.length > 0) {
      // eslint-disable-next-line
      const filteredProduct = products.filter((item: ProductInterface) => {
        if (item?.product?.barcode === barcode || item?.product?.barcode?.includes(barcode)===true || item?.product?.name.toLowerCase().replace(/ +/g, "") === barcode.toLowerCase().replace(/ +/g, "")|| item?.product?.name.toLowerCase().replace(/ +/g, "")?.includes(barcode)===true ) {
          const newObj = {
            id: item.id,
            name: item.product.name,
            quantity: 0,
            price: item.printedMrp,
            itemId:item?.product?.id,
            barcode:item.product?.barcode,
            weight:item?.product?.description
          };
          filteredNewArray = [...filteredNewArray, newObj];

          // filteredNewArray.push(newObj);
          return newObj;
        }
        // return filteredNewArray
      });

      if (!filteredProduct.length) {
        // setAddedProduct([]);
        return;
      }
      setAddedProduct([...addedProduct,...filteredNewArray]);
    }
    // eslint-disable-next-line
    },[products])

// const arr = addedProduct
// const ids = arr.map(o => o.itemId)
// const filtered = arr.filter(({itemId}, index) => !ids.includes(itemId, index + 1))

const arr = addedProduct;

const ids = arr?.map((o) => o.itemId);

var filtered:any = arr?.filter(
  ({ itemId }, index) => !ids?.includes(itemId, index + 1)
);

var debug = filtered?.filter(
  (x:any) =>
    x.name.toLowerCase().includes(barcode) ||
    x["barcode"].includes(barcode)
);

  return (
    <Box>
      <Box>
        <Box sx={{ width: "65%" }}>
          <RtvBarcodeInput filtered={filtered} value={barcode} name={"RTV"} onChange={((e:any)=>RtvDiscardBarcode(e,setBarcode,products,setAddedProduct,filtered))} />
        </Box>
        <AddedProduct debug={debug} addedProduct={filtered} barcode={barcode} setAddedProduct={setAddedProduct} name={"RTV"} submitForm={submitRtv} disabled={isDisabled} filtered={filtered} setBarcode={setBarcode} />  
      </Box>
    </Box>
  );
}
