import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootState } from "../../Interface/BillerIdInterface";
import { getProductsBackendSearch } from "../../pages/billing/action";

export default function DiscardBarcodeInput({ onChange, value,name ,filtered}: any) {
  const dispatch: any = useDispatch();
  const {billerIdData} = useSelector((state: RootState) => state.loginReducer);
  return (
    <Box
      sx={{
        maxWidth: "100%",
      }}
    >
      <TextField
        autoFocus={true}
        fullWidth
        label={name==="Return" ? "Search with invoice number" :"Search with name or scan barcode"}
        id="fullWidth"
        onChange={onChange}
        value={value?.toLowerCase()}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === "Enter" && !event.repeat) {
            event.preventDefault()
                 // eslint-disable-next-line
            {name==="Discard" && filtered.map((ele:any) => ele.barcode).includes(value)?"Discard":dispatch(getProductsBackendSearch(billerIdData,value))} 
          }
        }}
      />
    </Box>
  );
}
