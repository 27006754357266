import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import { getProductsBackendSearch } from "../../pages/billing/action";
import { useSelector } from "react-redux";

export default function RtvBarcodeInput({ onChange, value,name ,filtered}: any) {
  const dispatch: any = useDispatch();
  // const getStoreId = localStorage.getItem("storeId")
  const { storeId: getStoreId } = useSelector((state:any) => state.commonReducer);

  const isAlreadyAdded = filtered.some((item: any) => item.name.toLowerCase().includes(value));
  const modifiedBarcode = value.replace(/^0+/, '');

  return (
    <Box
      sx={{
        maxWidth: "100%",
      }}
    >
      <TextField
        autoFocus={true}
        fullWidth
        label={name==="Return" ? "Search with invoice number" :"Search with name or scan barcode"}
        id="fullWidth"
        onChange={onChange}
        value={value}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === "Enter" && !event.repeat) {
            event.preventDefault();
            if (isAlreadyAdded) {
              return;
            }
                 // eslint-disable-next-line
            {name==="RTV" && value?.length>=1  && filtered.map((ele:any) => ele.barcode).includes(value)?"RTV":dispatch(getProductsBackendSearch(getStoreId,modifiedBarcode))} 
          }
        }}
      />
    </Box>
  );
}
