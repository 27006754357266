import { makeStyles } from "@mui/styles";

export const useBillingStyles = makeStyles(() => ({
    cashCollectMethod: {
      backgroundColor: "black",
      color: "white",
      padding: "10px 25px",
      marginRight: "1px !important",
      cursor: "pointer",
    },
    amount: {
      width: 60,
      "&>input": {
        padding: 10,
        boxSizing: "border-box",
        width: "100%",
      },
    },
  }));