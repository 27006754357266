import ItemCard from "../../component/card/productCard";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { useStyles } from "./useStyles";
import { ProductInterface } from "../../Interface/ProductsInterface";
import RtvDiscardDecrementFunction from "../../utility/RtvDiscardDecrementFunction";
import RtvDiscardIncrementFunction from "../../utility/RtvDiscardIncrementFunction";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";

interface Props{
  debug:any
  addedProduct:ProductInterface[],
  barcode:string;
  name:string;
  submitForm:() => void;
  setAddedProduct:any;
  disabled:boolean;
  filtered:any;
  setBarcode:any
}

function AddedProduct({debug,addedProduct,barcode,setAddedProduct,name,submitForm,disabled,filtered,setBarcode}:Props) {
  const classes = useStyles();

  var cartProducts:any = filtered.map((product:any) => product.quantity>0 && product);

  var myFilterArray = cartProducts.filter(Boolean);

  const [total, setTotal] = useState<number>(0);


 useEffect(() => {
    let total: number = 0;
    filtered.forEach((singleTotal: any) => {
      total = total + +singleTotal.price * singleTotal.quantity;
    });
    setTotal(total);
  },[addedProduct,filtered])

  return (
    <>
    <Box sx={{ mt: 2, display: "flex", flexWrap: "wrap", width: "65%" }}>
    {barcode!==null && barcode!==""  && (
        <>
          {debug?.map((card: any) => (
              <ItemCard
                key={card.id}
                productName={card.name}
                quantity={card.quantity}
                price={card.price}
                increaseQuantity={() => RtvDiscardIncrementFunction(card.id,addedProduct,setAddedProduct,setBarcode)}
                decreaseQuantity={() => RtvDiscardDecrementFunction(card.id,addedProduct,setAddedProduct)}
                weight={card.weight}
                name={"Discard"}
                />
            // )
          ))}
        </>
      )}
      <>
      {barcode==="" && myFilterArray.map((card:any) => (
       <ItemCard
       key={card.id}
       productName={card.name}
       quantity={card.quantity}
       price={card.price}
       increaseQuantity={() => RtvDiscardIncrementFunction(card.id,addedProduct,setAddedProduct,setBarcode)}
       decreaseQuantity={() => RtvDiscardDecrementFunction(card.id,addedProduct,setAddedProduct)}
       weight={card.weight}
       name={"Discard"}
       />
))}
      </>
        </Box>
        {addedProduct?.map((item: any) => {
                  if (item.quantity > 0) {
                    return (
                      <>
          <Paper
            sx={{
              width: 400,
              position: "fixed",
              top: 0,
              bottom: 0,
              right: 0,
              zIndex: 99,
              padding: 2,
            }}
          >
            <Box sx={{ position: "relative", height: "97vh" }}>
              {addedProduct.length &&
                // eslint-disable-next-line
                addedProduct?.map((item: any) => {
                  if (item.quantity > 0) {
                    return (
                      <>
                        {item.quantity && (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography>
                              {`${item.name} ${item.price}x${item.quantity}`}{" "}
                              :-
                            </Typography>
                            <Typography>
                              Rs {parseInt(item.price) * item.quantity}
                            </Typography>
                          </Box>
                        )}
                      </>
                    );
                  }
                }
                )}
                 {filtered.length && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop:"20px"
                    }}
                  >
                    <Typography component="div">Total:-</Typography>
                    <Typography component="div">Rs {total}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderTop: "2px solid black",
                      borderBottom: "2px solid black",
                      py: 1,
                      mt: 2,
                    }}
                  >
                    <Typography variant="h4">Grand Total :-</Typography>
                    <Typography component="div" className={classes.amount}>
                      {Math.round(total)}           
                    </Typography>{" "}
                  </Box>
                </>
              )}
            </Box>
                      <Box 
                       sx={{
                        position: "absolute",
                        bottom: 0,
                        display: "flex",
                        mt: 2,
                        justifyContent:"center",
                        right:20,
                        marginBottom:"10px"
                      }}
                      >
                      <Button disabled={disabled} onClick={name==="Discard"?submitForm:submitForm} className ={classes.rtvButton}>{name==="Discard"?"Submit Discard":"Submit RTV"}</Button>
                    </Box>
          </Paper> 
          </>
            )}})}
       
        </>
  )
}

export default AddedProduct