import Button from '@mui/material/Button';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import ReactToPrint from 'react-to-print';
import LoadingComponent from '../../utility/LoadingComponent';

function CashoutPrint() {
  const componentRef = useRef<HTMLDivElement>(null); 
  const {addedTime} = useSelector((state: any) => state.cashoutReducer);
  const {addedReason} = useSelector((state: any) => state.cashoutReducer);
  const {addedCashout} = useSelector((state: any) => state.cashoutReducer);
  const {userDoneBy} = useSelector((state: any) => state.cashoutReducer);

  var serverTime = addedTime?.replace('T',' ');
  let today = serverTime
  today = today?.split('.')[0]

  return (
    <>
    {addedReason==="" || addedCashout==="" ? <LoadingComponent /> :(
      <>
    <div style={{marginLeft:"10px"}} ref={componentRef}>
      <h1>{today}</h1>
        <h3>CASHOUT OF RS.</h3>
        <p>{addedCashout} for {addedReason}</p>
        <h3>Done By:</h3>
          <p>{userDoneBy}</p>
    </div>
    <ReactToPrint
        trigger={() =>  <Button style={{position: "absolute",right:0,bottom: "20px",marginRight:"50px"}} variant="outlined">
        Print
    </Button> }
        content={() => componentRef.current}
      />
      </>
    )}
    </>

  )
}

export default CashoutPrint