import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { makeStyles } from "@mui/styles";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    padding: 10,
    borderBottom: "1px solid black",
  },
}));

export default function BasicBreadcrumbs() {
  const location = useLocation();
  const classes = useStyles();

  return (
    <div role="presentation" className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary" sx={{ textTransform: "capitalize" }}>
          {location?.pathname.substring(1)}
        </Typography>
      </Breadcrumbs>
    </div>
  );
}
