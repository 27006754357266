import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { TableHead } from '@mui/material';
import { useSelector } from 'react-redux';
import { AuditTableTS } from '../../Interface/AuditInterface';
import LoadingComponent from '../../utility/LoadingComponent';
import React , {useMemo, useState} from "react"
import PrintModal from '../../component/modal/PrintModal';
import AuditPdf from './AuditPdf';

function AuditTable() {
    const {auditData,loading} = useSelector((state: any) => state.cashoutNewReducer);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [reason,setReason] = useState("");
    const [cashout,setCashout] = useState("");
    const [amount,setAmount] = useState("");
    const [time,setTime] = useState();

    let today:string | Date = new Date();
    let dd:number | string = today.getDate();
    let mm:number | string = today.getMonth() + 1;
    let yyyy:number = today.getFullYear();
        
    today = mm + '/' + dd + '/' + yyyy;
    today = new Date(Date?.parse(today))?.toLocaleDateString();
    
    const sum = useMemo(()=>{
      return(
       auditData
    .map((item:AuditTableTS) => parseInt(item.cashOutAmount))
    .reduce((prev:string, curr:string) => prev + curr, 0)
      )
      // eslint-disable-next-line
  },[auditData])
    
    var sorted_meetings = auditData.sort((a:AuditTableTS,b:AuditTableTS) => {
      return new Date(a.addDate).getTime() - 
          new Date(b.addDate).getTime()
  }).reverse();

  const openFormModal = (a:any) => {
    setConfirmOpen(true);
    setReason(a?.reason)
    setCashout(a?.doneBy?.userName.charAt(0).toUpperCase() + a?.doneBy?.userName?.slice(1))
    setAmount(a?.cashOutAmount)
    setTime(a?.addDate)
  };

  return (
    <>
     {loading===true ? <LoadingComponent />:(
      <>
          {confirmOpen === true && (
            <PrintModal
              children={
                confirmOpen && (
                  <>
                    <AuditPdf
                    reason={reason}
                    cashout={cashout}
                    amount={amount}
                    time={time}
                    />
                  </>
                )
              }
              handleClose={() => {
                setConfirmOpen(false);
              }}
              open={confirmOpen}
            />
          )}
      <TableContainer>
    <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
    <TableHead>
        <TableRow>
          <TableCell><b>S.No</b></TableCell>
          <TableCell><b>Reason</b></TableCell>
          <TableCell><b>CashOut</b></TableCell>
          <TableCell><b>Amount</b></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {sorted_meetings?.map((a:AuditTableTS,i:number)=>(
        <React.Fragment key={a?.id}>
        <TableRow key={a?.id} style={{cursor:"pointer"}} onClick={() => {openFormModal(a)}}>
            <TableCell style={{ width: 400 }}>
              {i+1} 
            </TableCell>
            <TableCell style={{ width: 400 }}>
              {a?.reason}
            </TableCell>
            <TableCell style={{ width: 400 }}>
              {a?.doneBy?.userName.charAt(0).toUpperCase() + a?.doneBy?.userName?.slice(1)}
            </TableCell>
            <TableCell style={{ width: 400 }}>
              {a?.cashOutAmount}
            </TableCell>
            </TableRow>
            </React.Fragment>
            ))}
             <TableRow>
          <TableCell colSpan={3} align="left"><b>Total</b></TableCell>
          <TableCell colSpan={1} align="left"><b>Rs {sum}</b></TableCell>
        </TableRow>
      </TableBody>
      </Table>
      </TableContainer>
      </>
     )}
    </>
    
  )
}

export default AuditTable