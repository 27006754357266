import Utils from '../../utility'
export const getProducts = () => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    })
    const { order, sortField, offset, search } = getState().productsReducer
    Utils.api.getApiCall(
      Utils.endPoints.storeStock,
      `/1?order=${order}&sortField=${sortField}&offset=${offset}&take=${50}&search=${search}`,
      (respData: any) => {
        dispatch({
          type: Utils.ActionName.PRODUCTS,
          payload: {
            products: respData.data?.storeStocks,
            count: respData?.data?.count,
          },
        })
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
      },
      (error: any) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
        let { data } = error
        if (data?.message === '' || !data) {
          Utils.showAlert(2, 'Store Stocks Get Error:')
        } else {
          Utils.showAlert(2, 'Store Stocks Get Error: ' + data?.message)
        }
      },
    )
  }
}
//customer pin api
export const CustomerPin = (id: any, setFiledValue: any) => {
  return (dispatch: any) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    })
    Utils.api.getApiCall(
      Utils.endPoints.customerPin,
      `?pin=${id}`,
      (respData: any) => {
        setFiledValue('customerName', respData.data?.name)
        setFiledValue('phoneNumber', respData.data?.phoneNumber)

        dispatch({
          type: Utils.ActionName.PRODUCTS,
          payload: {
            pin: respData.data,
          },
        })
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
      },
      (error: any) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
        let { data } = error
        if (data?.message === '' || !data) {
          Utils.showAlert(2, 'Customer By Pin Get Error:')
        } else {
          Utils.showAlert(2, 'Customer By Pin Get Error: ' + data?.message)
        }
      },
    )
  }
}

export const creatOrder = (
  values: any,
  products: any,
  totalAndDiscounts: any,
  setOpen: any,
  open?: any,
  onlinePinValue?: any,
  setAddedProduct?: any,
  storeId?: any,
  setBarcode?: any,
  setIsDisabled?: any,
  setDiscountPercentage?: any,
  setDiscountAmount?: any,
  setButtonClicked?: any,
  StoreID?: any,
) => {
  return (dispatch: any, getState: any) => {
    const { storeId } = getState().commonReducer

    console.log(StoreID)
    setButtonClicked(true)
    setIsDisabled(true)
    const { grandTotal, discounPersentage, discounAmount } = totalAndDiscounts
    let dataToSend: any = {}
    products.forEach((item: any) => {
      item.quantity > 0 &&
        (dataToSend = {
          product: products.map((item: any) => {
            return {
              product: item.id,
              quantity: item.quantity,
              sellingPrice: item.sellingPrice,
            }
          }),
          customer: {
            customerName: values.customerName,
            customerPhoneNumber:
              values.phoneNumber === '' ? null : values.phoneNumber,
            gst: values.gst,
            email: values.email,
            companyName: values.companyName,
            newAddress: values.address,
          },
          paymentMethod: open ? open : '',
          store: parseInt(storeId || StoreID),
          age: values.ageGroup ? values.ageGroup : '',
          notes: '',
          gender: values.gender ? values.gender : '',
          orderAmountTotal: grandTotal,
          discountAmount: discounAmount === '' ? 0 : discounAmount,
          discountPercent: discounPersentage === '' ? 0 : discounPersentage,
          // eslint-disable-next-line
          orderType:
            (values?.payType === 'swiggy' && 'swiggy') ||
            (values?.payType === 'zomato' && 'zomato') ||
            (values?.payType === 'ondc' && 'ondc') ||
            // (values?.payType === 'paytm' && 'paytm') ||
            'pos',
          walletUsed:
            onlinePinValue && !discounAmount && !discounPersentage
              ? onlinePinValue
              : 0,
        })
    })

    if (!dataToSend.gender) {
      delete dataToSend.gender
    }
    if (!dataToSend.age) {
      delete dataToSend.age
    }
    if (!dataToSend.notes) {
      delete dataToSend.notes
    }
    if (!dataToSend.paymentMethod) {
      delete dataToSend.paymentMethod
    }
    Utils.api.postApiCall(
      Utils.endPoints.orderCreate,
      dataToSend,
      (respData: any) => {
        Utils.showAlert(1, 'Success')
        setOpen('')
        dispatch(orderInvoice(respData.data.stateInvoiceNo))
        setAddedProduct([])
        setBarcode('')
        setDiscountPercentage(0)
        setDiscountAmount(0)
        setIsDisabled(false)
        setButtonClicked(false)
      },
      (error: any) => {
        let { data } = error
        // eslint-disable-next-line
        if (
          data?.message == 'customerPhoneNumber must be a valid phone number'
        ) {
          // eslint-disable-next-line
          Utils.showAlert(
            2,
            'Enter Valid Phone Number ' +
              data?.message +
              '.' +
              ' It should be of 10 digits',
          )
          setIsDisabled(true)
        } else if (data?.message === '' || !data) {
          Utils.showAlert(2, 'Orders Add Error')
          setIsDisabled(true)
        } else {
          Utils.showAlert(2, 'Orders Add Error: ' + data?.message)
          setIsDisabled(true)
        }
        // setAddedProduct([])
        setBarcode('')
      },
    )
  }
}

export const orderInvoice = (id: any) => {
  return (dispatch: any) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    })
    Utils.api.getApiCall(
      Utils.endPoints.orderInvoice + id,
      '',
      (respData: any) => {
        var winparams =
          'dependent=yes,locationbar=no,scrollbars=yes,menubar=yes,' +
          'resizable,screenX=50,screenY=50,width=850,height=1050'
        var htmlPop =
          '<embed width=100% height=100%' +
          ' type="application/pdf"' +
          ' src="data:application/pdf;base64,' +
          escape(respData?.data) +
          '"></embed>'
        var printWindow = window.open('', 'PDF', winparams)
        //@ts-ignore
        printWindow.document.write(htmlPop)
        //@ts-ignore
        // printWindow.print()

        // const linkSource = `data:application/pdf;base64,${respData.data}`;
        // const downloadLink = document.createElement("a");
        // const fileName = "abc.pdf";
        // downloadLink.href = linkSource;
        // downloadLink.download = fileName;
        // downloadLink.click();

        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
        // Utils.showAlert(1, 'successfully')
      },
      (error: any) => {
        let { data } = error
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
        if (data?.message === '' || !data) {
          Utils.showAlert(2, 'Invoice Get Error')
        } else {
          Utils.showAlert(
            2,
            'Invoice Get Error: ' + data?.error || data?.message,
          )
        }
      },
    )
  }
}
export const getProductsBackendSearch: any = (
  billerIdData: any,
  barcode?: any,
) => {
  return (dispatch: any, getState: any) => {
    // const StoreID = localStorage.getItem('storeId')
    const { storeId: StoreID } = getState().commonReducer
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    })
    const { order, sortField, offset } = getState().productsReducer
    Utils.api.getApiCall(
      Utils.endPoints.storeStock,
      `/${StoreID}?order=${order}&sortField=${sortField}&offset=${offset}&take=${15}&search=${barcode}`,
      (respData: any) => {
        dispatch({
          type: Utils.ActionName.PRODUCTS,
          payload: {
            products: respData.data?.storeStocks,
            // count: respData?.data?.count,
          },
        })
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
      },
      (error: any) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
        let { data } = error
        if (data?.message === '' || !data) {
          Utils.showAlert(2, 'Store Stocks Get Error:')
        } else {
          Utils.showAlert(2, 'Store Stocks Get Error: ' + data?.message)
        }
      },
    )
  }
}

export const getCustomernamebynumber: any = (
  Phonenumber: any,
  setFiledValue: any,
  setIsDisabled: any,
) => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    })
    Utils.api.getApiCall(
      Utils.endPoints.customerName,
      `getCustomerFromPhoneNumber?phoneNumber=${Phonenumber}`,
      (respData: any) => {
        setFiledValue('customerName', respData.data.name.toString())
        setFiledValue('phoneNumber', respData.data.phoneNumber.toString())
        setIsDisabled(false)
        dispatch({
          type: Utils.ActionName.PRODUCTS,
          payload: {
            loading: false,
          },
        })
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
      },
      (error: any) => {
        // let { data } = error
        setFiledValue('customerName', '')
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
        //   if(data?.message === "" || !data) {
        //     Utils.showAlert(2,"Customers Name Get Error:")
        // }else{
        //     Utils.showAlert(2,"Customers Name Get Error: " + data?.message)
        // }
      },
    )
  }
}
