import Utils from '../../utility';

export const getProducts = () => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    })
    const {
      order,
      sortField,
      offset,
      search,
    } = getState().productsReducer
    Utils.api.getApiCall(
      Utils.endPoints.storeStock,
      `/1?order=${order}&sortField=${sortField}&offset=${offset}&take=${50}&search=${search}`,
      (respData: any) => {
        dispatch({
          type: Utils.ActionName.PRODUCTS,
          payload: {
            products: respData.data?.storeStocks,
            count: respData?.data?.count,
          },
        })
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
      },
      (error: any) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
        let { data } = error
        if(data?.message === "" || !data) {
          Utils.showAlert(2,"Store-Stock Get Error")
      }else{
          Utils.showAlert(2,"Store-Stock Get Error: " +  data?.message)
      }
      },
    )
  }
}
//post api
export const createRtvandDiscordStock = (products: any, storeId: any, bool: string, name: string, setAddedProduct: any, setBarcode: any, setIsDisabled: any) => {
  return (dispatch: any) => {
    let dataToSend = {}
    dataToSend = {
      store: parseInt(storeId),
      // warehouse: 0,
      discard: bool,
      product: products?.map((item: any) => {
        return {
          product: item?.itemId,
          quantity: item?.quantity,
        }
      }),
    }
    Utils.api.postApiCall(
      Utils.endPoints.rtvStoreStockCreate,
      dataToSend,
      (respData: any) => {
        Utils.showAlert(1, 'Success')
        // eslint-disable-next-line
        { name === "Rtv" && dispatch(discardInvoice(respData.data.id)) }
        // eslint-disable-next-line
        { name === "Discard" && dispatch(discardInvoice(respData.data.id)) }
        setAddedProduct([])
        setBarcode("")
        setIsDisabled(false)
      },
      (error: any) => {
        let { data } = error
        if(data?.message === "" || !data) {
          Utils.showAlert(2,`${name} Store-Stock Add Error`)
      }else{
          Utils.showAlert(2,`${name} Store-Stock Add Error: ` +  data?.message)
      }
        setAddedProduct([])
        setBarcode("")
      },
    )
  }
}

export const discardInvoice = (id: any) => {
  return (dispatch: any) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    })
    Utils.api.getApiCall(
      Utils.endPoints.rtvInvoice + id,
      '',
      (respData: any) => {
        var winparams =
          'dependent=yes,locationbar=no,scrollbars=yes,menubar=yes,' +
          'resizable,screenX=50,screenY=50,width=850,height=1050'
        var htmlPop =
          '<embed width=100% height=100%' +
          ' type="application/pdf"' +
          ' src="data:application/pdf;base64,' +
          escape(respData?.data) +
          '"></embed>'
        var printWindow = window.open('', 'PDF', winparams)
        //@ts-ignore
        printWindow.document.write(htmlPop)
        //@ts-ignore
        // printWindow.print()
        // const linkSource = `data:application/pdf;base64,${respData.data}`;
        // const downloadLink = document.createElement("a");
        // const fileName = "abc.pdf";
        // downloadLink.href = linkSource;
        // downloadLink.download = fileName;
        // downloadLink.click();
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
      },
      (error: any) => {
        let { data } = error
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        })
        if(data?.message === "" || !data) {
          Utils.showAlert(2,"Rtvstore-stocks-pdf Get Error")
      }else{
          Utils.showAlert(2,"Rtvstore-stocks-pdf Get Error: " +  data?.error || data?.message)
      }
      },
    )
  }
}