import Button from '@mui/material/Button';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import ReactToPrint from 'react-to-print';

function AuditPdf({reason,cashout,amount,time}:any) {
    const componentRef = useRef<HTMLDivElement>(null); 
    // const getStoreId = localStorage.getItem("storeId")
    const { storeId: getStoreId } = useSelector(
      (state: any) => state.commonReducer
    );
    var serverTime = time?.replace('T',' ');
    let today = serverTime
    today = today?.split('.')[0]

  return (
    <>
        <div style={{marginLeft:"10px"}} ref={componentRef}>
        <h1>{today}</h1>
            <h2>Store:- {getStoreId}</h2>
        <h3>Reason:- {reason}</h3>
        <h4>Cashout:- {cashout}</h4>
        <h4>Amount:- {amount}</h4>
    </div>
     <ReactToPrint
        trigger={() =>  <Button style={{position: "absolute",right:0,bottom: "20px",marginRight:"50px"}} variant="outlined">
        Print
    </Button> }
        content={() => componentRef.current}
      />
    </>

  )
}

export default AuditPdf