import Utils from '../../utility'

const initialState = {
  ordersList: [],
  images: [],
  filterdOrder: [],
  runnerList: [],
  count: 0,
  order: 'DESC',
  sortField: 'name',
  offset: 1,
  take: 10,
  search: '',
  loading: true,
}
export const ordersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case `${Utils.ActionName.ORDERS}`:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return { ...state }
  }
}
