import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { TableHead } from '@mui/material';
import { useDispatch } from 'react-redux';
import { orderInvoice } from '../billing/action';
import { Order } from '../../Interface/ReturnInterface';
import LoadingComponent from '../../utility/LoadingComponent';
import { memo } from "react";

function DashboardSalesTable({salesData,loading}:any) {
    const dispatch: any = useDispatch();

  return (
       <TableContainer>
    {loading===true ? <LoadingComponent />:(
      <>
      <Table>
      <TableHead>
          <TableRow>
          <TableCell><b>S.No</b></TableCell>
            <TableCell><b>Order Type</b></TableCell>
            {/* <TableCell><b>Date & Time</b></TableCell> */}
            <TableCell><b>Invoice Number</b></TableCell>
            <TableCell><b>Payment Method</b></TableCell>
            <TableCell><b>Amount</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {salesData?.map((a:Order,i:number)=>(
        <TableRow key={a?.id} style={{cursor: "pointer"}} onClick={() =>dispatch(orderInvoice(a?.stateinvoiceno))}>
               <TableCell style={{ width: 400 }}>
                  {i+1} 
            </TableCell>
              <TableCell style={{ width: 400 }}>
                {a?.ordertype?.charAt(0).toUpperCase() + a?.ordertype?.slice(1).substring(0, 6)}
              </TableCell>
              {/* <TableCell style={{ width: 400 }}>
              {new Date(a?.createdat).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })}
              </TableCell> */}
              <TableCell style={{ width: 400 }}>
                {a?.stateinvoiceno}
              </TableCell>
              <TableCell style={{ width: 400 }}>
                {a?.paymentmethod?.charAt(0).toUpperCase() + a?.paymentmethod?.slice(1)}
              </TableCell>
              <TableCell style={{ width: 400 }}>
               {a?.orderamounttotal}
              </TableCell>
              </TableRow>
               ))} 
        </TableBody>
        </Table>
        </>
        )}
        </TableContainer> 
  )
}

export default memo(DashboardSalesTable)
