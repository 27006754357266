import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Schema from "../../schema";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import { creatOrder, getCustomernamebynumber } from "./action";
import Input from "../../component/inputs/input";
import { Box } from "@mui/material";
import { RootState } from "../../Interface/BillerIdInterface";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const AddUser = ({
  setOpen,
  addedProduct,
  totalAndDiscounts,
  open,
  setAddedProduct,
  setBarcode,
  wallet,
  setDiscountPercentage,
  setDiscountAmount
}:
any) => {
  const dispatch: any = useDispatch();
  const {billerIdData} = useSelector((state: RootState) => state.loginReducer);
  const [onlinePinValue, setOnlinePinValue] = useState<string>("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [buttonClicked, setButtonClicked] = useState(false);

  useEffect(() => {
    setOnlinePinValue(wallet);
       // eslint-disable-next-line
  }, [wallet,onlinePinValue]);

  const initialValues = {
    phoneNumber: "",
    customerName: "",
    gst:"",
    companyName:"",
    address:"",
    email:"",
  };

  const buttonStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 16px", // Add padding for a button-like appearance
    cursor: "pointer", // Change cursor on hover
    transition: "background-color 0.3s, color 0.3s"
  };

  // const newhandleChange = (e: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
  //   if (e.target.value.length >= 4) {
  //     dispatch(CustomerPin(e.target.value, setFieldValue));
  //   }
  // };

  const newhandleChangePhone =(e: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    if(e.target.value.length===10){
      setIsDisabled(false)
      dispatch(getCustomernamebynumber(e.target.value, setFieldValue,setIsDisabled))
    }
  }
   
  const filterProduct = addedProduct?.map((a:any)=>a.quantity>0&&a)

  const lastFinalProduct = filterProduct.filter(Boolean)
  // const StoreID:string | null = localStorage.getItem('storeId')
  // const { storeId: StoreID } = getState().commonReducer
  const { storeId: StoreID } = useSelector((state:any) => state.commonReducer);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Schema.offlineCashCollectPopup}
      onSubmit={(values) => {
        // if (!StoreID) return;
      dispatch(
          creatOrder(
            values,
            lastFinalProduct,
            totalAndDiscounts,
            setOpen,
            open,
            onlinePinValue,
            setAddedProduct,
            billerIdData,
            setBarcode,
            setIsDisabled,
            setDiscountPercentage,
            setDiscountAmount,
            setButtonClicked,
            StoreID
          )
        )
      }}
    >
      {({
        values,
        errors,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
                    <Box sx={{ display: "flex", mb: 2 }}>
            <Input
              value={values.phoneNumber}
              name="phoneNumber"
              type="text"
              label="Phone Number"
              onChange={
                (e) => {
                  handleChange(e);
                  newhandleChangePhone(e, setFieldValue)                }
              }
              handleBlur={handleBlur}
            />
          </Box>
          <Box sx={{ display: "flex", mb: 2 }}>
            <Input
              disabled={values.phoneNumber.length < 10}
              // defaultValue={pin?.name}
              value={values.customerName}
              name="customerName"
              type="text"
              error={errors.customerName}
              placeholder="Customer Name"
              label="Customer Name"
              onChange={handleChange}
              handleBlur={handleBlur}
            />
          </Box>
          {/* <Box sx={{ display: "flex", mb: 2 }}>
            <Input
              // value={values.pin}
              name="OnlinePin"
              type="text"
              label="Online Pin"
              onChange={(e: any) => newhandleChange(e, setFieldValue)}
              handleBlur={handleBlur}
            />
          </Box> */}
          {open==="credit" && (
            <Box>
          <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Advanced</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={buttonStyles}>GST</Typography>
          <Box sx={{ display: "flex", mb: 2 }}>
            <Input
              value={values.gst}
              name="gst"
              type="text"
              error={errors.gst}
              placeholder="GST"
              label="GST"
              onChange={handleChange}
              handleBlur={handleBlur}
            />
          </Box>
          <Typography
            style={buttonStyles}
          >
            Company Name
          </Typography>
          <Box sx={{ display: "flex", mb: 2 }}>
            <Input
              value={values.companyName}
              name="companyName"
              type="text"
              error={errors.companyName}
              placeholder="Company Name"
              label="Company Name"
              onChange={handleChange}
              handleBlur={handleBlur}
            />
          </Box>
          <Typography
          style={buttonStyles}
          >
            Address
          </Typography>
          <Box sx={{ display: "flex", mb: 2 }}>
            <Input
              value={values.address}
              name="address"
              type="text"
              error={errors.address}
              placeholder="Address"
              label="Address"
              onChange={handleChange}
              handleBlur={handleBlur}
            />
          </Box>
          <Typography
            style={buttonStyles}
          >
            Email
          </Typography>
          <Box sx={{ display: "flex", mb: 2 }}>
            <Input
              value={values.email}
              name="email"
              type="email"
              error={errors.email}
              placeholder="EMAIL"
              label="EMAIL"
              onChange={handleChange}
              handleBlur={handleBlur}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
          </Box>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop:"20px"
            }}
          >
            <Button disabled={open==="credit"?isDisabled:buttonClicked} variant="outlined" type="submit">
              {open==="credit"? "Create Bill" :"Payment collect"}
            </Button>
            <Button variant="outlined" sx={{ ml: 2 }}>
              Cancel
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default AddUser;
