// import moment from "moment";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Schema from "../../schema";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import { creatOrder, getCustomernamebynumber } from "./action";
import Input from "../../component/inputs/input";
import { Box } from "@mui/material";
import Select from "../../component/inputs/select";
import { useSelector } from "react-redux";
import { useState } from "react";
import { RootState } from "../../Interface/BillerIdInterface";
// import Utils from "../../utility";

const AddUser = ({
  setOpen,
  isEdit,
  open,
  addedProduct,
  totalAndDiscounts,
  setAddedProduct,
  setBarcode,
  setDiscountPercentage,
  setDiscountAmount,
}: any) => {
  const dispatch: any = useDispatch();
  const { billerIdData } = useSelector(
    (state: RootState) => state.loginReducer
  );
  // const [paytmValue, setPaytmValue] = useState(true);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  // const [loading, setLoading] = useState(false);

  const initialValues = {
    payType: isEdit?.length ? isEdit[0].userType : "",
    phoneNumber: isEdit?.length ? isEdit[0].name : "",
    customerName: isEdit?.length ? isEdit[0].userName : "",
  };

  const filterProduct = addedProduct?.map((a: any) => a.quantity > 0 && a);

  const lastFinalProduct = filterProduct.filter(Boolean);

  const newhandleChangePhone = (
    e: any,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    if (e.target.value.length === 10) {
      dispatch(getCustomernamebynumber(e.target.value, setFieldValue));
    }
  };

  // const CurrentTime = moment().format("YYYY-MM-DD HH:mm:ss");

  // const handlePaytmClick = async (data: any) => {
  //   const idLength = 32;
  //   const digits = "0123456789";

  //   let id = "";
  //   for (let i = 0; i < idLength; i++) {
  //     const randomIndex = Math.floor(Math.random() * digits.length);
  //     id += digits[randomIndex];
  //   }
  //   if (data === "paytm") {
  //     setPaytmValue(false);
  //     try {
  //       const url = "https://uatservices.thenewshop.in/api/v1/paytm";

  //       const requestBody = {
  //         url: "/ecr/payment/request",
  //         paytm_environment: "https://securegw-stage.paytm.in",
  //         head: {
  //           version: "3.1",
  //           requestTimeStamp: CurrentTime,
  //           channelId: "EDC",
  //         },
  //         body: {
  //           merchantTransactionId: id,
  //           paytmMid: "TheNew27680378538345",
  //           paytmTid: "70000878",
  //           transactionDateTime: CurrentTime,
  //           transactionAmount: (totalAndDiscounts.grandTotal * 100).toString(),
  //         },
  //       };

  //       const response = await fetch(url, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(requestBody),
  //       });

  //       if (response.ok) {
  //         setLoading(true);
  //         const initiateCode = async () => {
  //           const url = "https://uatservices.thenewshop.in/api/v1/paytm";
  //           const requestBody = {
  //             url: "/ecr/payment/status",
  //             paytm_environment: "https://securegw-stage.paytm.in",
  //             head: {
  //               version: "3.1",
  //               requestTimeStamp: CurrentTime,
  //               channelId: "EDC",
  //             },
  //             body: {
  //               paytmMid: "TheNew27680378538345",
  //               paytmTid: "70000878",
  //               transactionDateTime: CurrentTime,
  //               merchantTransactionId: id,
  //             },
  //           };
  //           let statusDataa;
  //           try {
  //             const response = await fetch(url, {
  //               method: "POST",
  //               headers: {
  //                 "Content-Type": "application/json",
  //               },
  //               body: JSON.stringify(requestBody),
  //             })
  //               .then((response) => response.json())
  //               .then(
  //                 (json) => (statusDataa = json.body.resultInfo.resultCode)
  //               );

  //             if (statusDataa === "S") {
  //               // console.log(response, "status");
  //               setLoading(false);
  //             } else if (statusDataa === "F") {
  //               Utils.showAlert(2, "The sale txn has failed");
  //               setLoading(false);
  //             } else {
  //               console.error("Request failed");
  //               setTimeout(initiateCode, 10000); // Retry after 10 seconds
  //             }
  //           } catch (error) {
  //             console.error(error);
  //             setTimeout(initiateCode, 10000); // Retry after 10 seconds
  //           }
  //         };
  //         setTimeout(initiateCode, 10000); // Start after 10 seconds
  //       } else {
  //         console.error("Request failed");
  //       }
  //     } catch (error) {
  //       console.error("Error:", error);
  //     }
  //   }
  // };

  // const handleClosePaytm = async () => {
  //   const idLength = 32;
  //   const digits = "0123456789";

  //   let id = "";
  //   for (let i = 0; i < idLength; i++) {
  //     const randomIndex = Math.floor(Math.random() * digits.length);
  //     id += digits[randomIndex];
  //   }
  //   const url = "https://uatservices.thenewshop.in/api/v1/paytm";
  //   const requestBody = {
  //     url: "/ecr/void",
  //     paytm_environment: "https://securegw-stage.paytm.in",
  //     head: {
  //       version: "3.1",
  //       requestTimeStamp: CurrentTime,
  //       channelId: "EDC",
  //     },
  //     body: {
  //       paytmMid: "TheNew27680378538345",
  //       paytmTid: "70000878",
  //       transactionDateTime: CurrentTime,
  //       merchantTransactionId: id,
  //     },
  //   };

  //   try {
  //     const response = await fetch(url, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(requestBody),
  //     });

  //     if (response.ok) {
  //       setLoading(false);
  //       // console.log(response, "status");
  //       setPaytmValue(true);
  //     } else {
  //       console.error("Request failed");
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const handleRefundApply = async() => {
  //   const idLength = 32;
  //   const digits = "0123456789";

  //   let id = "";
  //   for (let i = 0; i < idLength; i++) {
  //     const randomIndex = Math.floor(Math.random() * digits.length);
  //     id += digits[randomIndex];
  //   }

  //   const length = 10;
  //   const characters = '0123456789';
  //   let orderId = '';

  //   for (let i = 0; i < length; i++) {
  //     const randomIndex = Math.floor(Math.random() * characters.length);
  //     orderId += characters[randomIndex];
  //   }

  //   const lengths = 10;
  //   const characterss = '0123456789';
  //   let refundId = '';

  //   for (let i = 0; i < lengths; i++) {
  //     const randomIndex = Math.floor(Math.random() * characterss.length);
  //     refundId += characterss[randomIndex];
  //   }

  //   const url = "http://localhost:8080/refund/apply";
  //   const requestBody = {
  //     head: {
  //       version: "3.1",
  //       requestTimeStamp: CurrentTime,
  //       channelId: "EDC",
  //     },
  //     body: {
  //       paytmMid: "TheNew27680378538345",
  //       paytmTid: "70000878",
  //       transactionDateTime: CurrentTime,
  //       merchantTransactionId: id,
  //       txnType      : "REFUND",
  //       orderId      : `ORDERID_${orderId}`,
  //       txnId        : id,
  //       refId        : `REFUNDID_${refundId}`,
  //       refundAmount : 1.00,
  //       // "mid"          : "TheNew27680378538345",
  //       // "txnType"      : "REFUND",
  //       // "orderId"      : "ORDERID_98765",
  //       // "txnId"        : "id",
  //       // "refId"        : "REFUNDID_98765",
  //       // "refundAmount" : "1.00",
  //     },
  //   };

  //   try {
  //     const response = await fetch(url, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(requestBody),
  //     });

  //     if (response.ok) {
  //       console.log(response, "status");
  //     } else {
  //       console.error("Request failed");
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // const handleRefundStatus = async() => {
  //   const idLength = 32;
  //   const digits = "0123456789";

  //   let id = "";
  //   for (let i = 0; i < idLength; i++) {
  //     const randomIndex = Math.floor(Math.random() * digits.length);
  //     id += digits[randomIndex];
  //   }
  //   const url = "http://localhost:8080/refund/status";
  //   const requestBody = {
  //     head: {
  //       version: "3.1",
  //       requestTimeStamp: CurrentTime,
  //       channelId: "EDC",
  //     },
  //     body: {
  //       paytmMid: "TheNew27680378538345",
  //       paytmTid: "70000878",
  //       transactionDateTime: CurrentTime,
  //       merchantTransactionId: id,
  //     },
  //   };

  //   try {
  //     const response = await fetch(url, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(requestBody),
  //     });

  //     if (response.ok) {
  //       console.log(response, "status");
  //     } else {
  //       console.error("Request failed");
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }
  // const StoreID:string | null = localStorage.getItem('storeId')
  const { storeId: StoreID } = useSelector((state:any) => state.commonReducer);

  return (
    <>
    <Formik
      initialValues={initialValues}
      validationSchema={Schema.onlineCashCollectPopup}
      onSubmit={(values) => {
        // if (buttonClicked) return;
        dispatch(
          creatOrder(
            values,
            lastFinalProduct,
            totalAndDiscounts,
            setOpen,
            open,
            0,
            setAddedProduct,
            billerIdData,
            setBarcode,
            setIsDisabled,
            setDiscountPercentage,
            setDiscountAmount,
            setButtonClicked,
            StoreID
          )
        );
      }}
    >
      {({
        values,
        errors,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
                    <Box sx={{ display: "flex", mb: 2 }}>
          <Input
              name="phoneNumber"
              type="text"
              label="Phone Number"
              onChange={(e: any) => newhandleChangePhone(e, setFieldValue)}
              handleBlur={handleBlur}
            />
          </Box>
          <Box sx={{ display: "flex", mb: 2 }}>
            <Input
              disabled={values.phoneNumber.length < 10}
              value={values.customerName}
              name="customerName"
              type="text"
              error={errors.customerName}
              label="Customer Name"
              onChange={handleChange}
              handleBlur={handleBlur}
            />
          </Box>
          <Box sx={{ display: "flex", mb: 2 }}>
          {/* <Select
              handleChange={handleChange}
              value={values.payType}
              label={"Pay Type"}
              option={["swiggy", "zomato","paytm"].map((item) => (
                <MenuItem value={item} onClick={()=>handlePaytmClick(item)}>{item}</MenuItem>
              ))}
              name="payType"
              error={errors.payType}
            /> */}
               <Select
              handleChange={handleChange}
              value={values.payType}
              label={"Pay Type"}
              option={["swiggy", "zomato","ondc"].map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
              name="payType"
              error={errors.payType}
            />
          </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                disabled={
                  open === "credit"
                  //  || loading === true
                    ? isDisabled
                    : buttonClicked
                }
                variant="outlined"
                type="submit"
              >
                Payment collect
              </Button>
              {/* <Button onClick={handleRefundApply}>refund call</Button>
            <Button onClick={handleRefundStatus}>refund status</Button> */}
              <Button
                // disabled={paytmValue}
                variant="outlined"
                sx={{ ml: 2 }}
                // onClick={handleClosePaytm}
              >
              Cancel Payment

                {/* Cancel Paytm Payment */}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddUser;
