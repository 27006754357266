import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { getProductsBackendSearch } from "../../pages/billing/action";
import { getProductsOrder } from "../../pages/return/action";
import Utils from "../../utility";
import { useSelector } from "react-redux";

type MyObject = {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: any;
  name?: string;
  filtered?: any;
};

export default function FullWidthTextField({
  onChange,
  value,
  name,
  filtered,
}: MyObject) {
  const dispatch: Dispatch = useDispatch();
  // const getStoreId = localStorage.getItem("storeId");
  const modifiedBarcode = value.replace(/^0+/, "");
  const { storeId: getStoreId } = useSelector((state:any) => state.commonReducer);

  // const isAlreadyAdded = filtered.some((item: any) => item.name.toLowerCase().includes(value));

  return (
    <Box
      sx={{
        maxWidth: "100%",
      }}
    >
      <TextField
        autoFocus={true}
        fullWidth
        label={
          name === "Return"
            ? "Search with invoice number"
            : "Search with name or scan barcode"
        }
        id="fullWidth"
        onChange={onChange}
        value={value?.toLowerCase()}
        onKeyDown={(event: any) => {
          if (event.key === "Enter" && !event.repeat) {
            event.preventDefault();
          // if (isAlreadyAdded) {
          //   return;
          // }
                  // eslint-disable-next-line
           {name==="Billing" && value?.length>=1 && filtered.map((ele:any) => ele.barcode).includes(value)?"POS":getStoreId ? dispatch(getProductsBackendSearch(getStoreId,modifiedBarcode)) : Utils.showAlert(2,"Store Id not found")} 
                 // eslint-disable-next-line
            {name==="Return" &&  dispatch(getProductsOrder(value,getStoreId))} 
          }
        }}
        autoComplete="off"
      />
    </Box>
  );
}
