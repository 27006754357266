import { BillingReturn } from '../Interface/BillingReturnInterface';

function DecrementFunction(key:number,addedProduct:any,setAddedProduct:any) {
    const updateProduct = addedProduct.map((item: BillingReturn) => {
        if (item.itemId === key) {
          const newArray = { ...item, quantity: --item.quantity };
          return newArray;
        } else return item;
      });
      setAddedProduct([...updateProduct]);
}

export default DecrementFunction