import * as React from "react";
import Box from "@mui/material/Box";
import BarcodeInput from "../../component/inputs/barcodeInput";
import ItemCard from "../../component/card/productCard";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import Modal from "../../component/modal";
import Cash from "./onlinePopup";
import Ofline from "./oflinePopup";
import { useSelector } from "react-redux";
import { BillingInterface } from "../../Interface/ProductsInterface";
import { useBillingStyles } from "../pagesUtils/useBillingStyles";
import { RootStateBillingProduct } from "../../Interface/BillerIdInterface";

export default function Index() {
  const { pin } = useSelector((state: any) => state.productsReducer);
  const classes = useBillingStyles();
  const [barcode, setBarcode] = React.useState("");
  const [open, setOpen] = React.useState<string>("");
  const [isOnline, setIsonline] = React.useState<boolean>(false);
  const { products } = useSelector(
    (state: RootStateBillingProduct) => state.productsReducer
  );
  const [addedProduct, setAddedProduct] = React.useState<BillingInterface[]>(
    []
  );
  const [grandTotal, setGrandTotal] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);
  const [cashReceive, setCashReceive] = React.useState();
  const [cashReturn, setCashReturn] = React.useState<string | number>();
  const [discounPersentage, setDiscountPercentage] = React.useState<number>(0);
  const [discounAmount, setDiscountAmount] = React.useState<number>(0);
  const inputRef = React.useRef<any>(0)
  
  const handleChangeBarcode = (e: any) => {
    // const val = e.target.value;
    setBarcode(e.target.value);
    let filteredNewArray: any = [];
    // if (addedProduct?.length) {
    //   const isAlreadyAdded = addedProduct.filter(
    //     (item: BillingInterface) => item.barcode === val
    //   );
    //   if (isAlreadyAdded.length) return;
    // }
    if (e.target.value.length >= 3) {
      // eslint-disable-next-line
      const modifiedBarcode = e.target.value.replace(/^0+/, '');
          // setBarcode(modifiedBarcode);
          // eslint-disable-next-line
      const filteredBarcodeProduct = filtered.map((item: any) => {
        if (
          item?.barcode === modifiedBarcode
        ) {
          const newObj = {
            id: item.id,
            name: item.name,
            quantity: filtered?.map((item: BillingInterface) => item.barcode === modifiedBarcode || item.barcode.includes(modifiedBarcode)  ? ++item.quantity : 1),
            barcode: item?.barcode,
            printedMrp: item.printedMrp,
            sellingPrice: item.sellingPrice,
            productCostPrice: item.productCostPrice,
            expiryDate: item.expiryDate,
            weight: item?.weight,
          };
          filteredNewArray = [...filteredNewArray, newObj];
          setBarcode("")
          // return newObj;
        }
      })
      // eslint-disable-next-line
      const filteredProduct = products.filter((item: BillingInterface) => {
        if (
          item?.product?.barcode === modifiedBarcode
        ) {
          const newObj = {
            id: item.product?.id,
            name: item.product.name,
            quantity: 1,
            barcode: item?.product?.barcode,
            printedMrp: item.printedMrp,
            sellingPrice: item.sellingPrice,
            productCostPrice: item.productCostPrice,
            expiryDate: item.expiryDate,
            weight: item?.product?.description,
            originalQuantity: item?.quantity
          };
          filteredNewArray = [...filteredNewArray, newObj];
          setBarcode("")
          // return newObj;
        }else if(
        item?.product?.name.toLowerCase().replace(/ +/g, "") ===
          e.target.value.toLowerCase().replace(/ +/g, "") ||
        item?.product?.name
          .toLowerCase()
          .replace(/ +/g, "")
          ?.includes(e.target.value) === true){
            const newObj = {
              id: item.product?.id,
              name: item.product.name,
              quantity: 0,
              barcode: item?.product?.barcode,
              printedMrp: item.printedMrp,
              sellingPrice: item.sellingPrice,
              productCostPrice: item.productCostPrice,
              expiryDate: item.expiryDate,
              weight: item?.product?.description,
              originalQuantity: item?.quantity
            };
            filteredNewArray = [...filteredNewArray, newObj];
          }
          // return filteredNewArray
        });
        if (!filteredProduct.length) {
          return;
        }
        if(!filteredBarcodeProduct.length){
          return;
        }
        setAddedProduct([...addedProduct, ...filteredNewArray]);
    }
  };

  React.useEffect(() => {
    let filteredNewArray: any = [];
    if (barcode.length >= 3) {
      // eslint-disable-next-line
      const modifiedBarcode = barcode.replace(/^0+/, '');
      // eslint-disable-next-line
      const filteredProduct = products.filter((item: BillingInterface) => {
        if (
          item?.product?.barcode === modifiedBarcode
        ) {
          const newObj = {
            id: item.product?.id,
            name: item.product.name,
            quantity: 1,
            barcode: item?.product?.barcode,
            printedMrp: item.printedMrp,
            sellingPrice: item.sellingPrice,
            productCostPrice: item.productCostPrice,
            expiryDate: item.expiryDate,
            weight: item?.product?.description,
            originalQuantity: item?.quantity
          };
          filteredNewArray = [...filteredNewArray, newObj];
          setBarcode("")
          return newObj;
        }else if(
        item?.product?.name.toLowerCase().replace(/ +/g, "") ===
          barcode.toLowerCase().replace(/ +/g, "") ||
        item?.product?.name
          .toLowerCase()
          ?.includes(barcode) === true){
            const newObj = {
              id: item.product?.id,
              name: item.product.name,
              quantity: 0,
              barcode: item?.product?.barcode,
              printedMrp: item.printedMrp,
              sellingPrice: item.sellingPrice,
              productCostPrice: item.productCostPrice,
              expiryDate: item.expiryDate,
              weight: item?.product?.description,
              originalQuantity: item?.quantity
            };
            filteredNewArray = [...filteredNewArray, newObj];
            return newObj;
          }
          // return filteredNewArray
        });
        if (!filteredProduct.length) {
          return;
        }
        setAddedProduct([...addedProduct, ...filteredNewArray]);
    }
    // eslint-disable-next-line
  }, [products]);

  const increaseQuantity = (key: number) => {
    const updateProduct = addedProduct.map((item: any) => {
      // eslint-disable-next-line
      if (item.id === key) {
        const newArray = { ...item, quantity: ++item.quantity };
        setBarcode("")
        return newArray;
      } else {
        setBarcode("")
        return item;
      }
    });
    setAddedProduct([...updateProduct]);
  };

  const updateProductAmount = (key:any,newAmount:any) => {
    const updateProduct = addedProduct.map((item: any) => {
      // eslint-disable-next-line
      if (item.id === key) {
        const newArray = { ...item, sellingPrice: newAmount };
        setBarcode("")
        return newArray;
      } else {
        setBarcode("")
        return item;
      }
    });
    setAddedProduct([...updateProduct]);
  };

  const decreaseQuantity = (key: number) => {
    const updateProduct = addedProduct
      .map((item: any) => {
        if (item.id === key) {
          if (item.quantity === 1) {
            return null;
          }
          const newArray = { ...item, quantity: --item.quantity };
          return newArray;
        } else return item;
      })
      .filter((item: BillingInterface) => item);
    setAddedProduct([...updateProduct]);
  };

  const arr = addedProduct;

  const ids = arr?.map((o) => o.id);
  var filtered:any = arr?.filter(
    ({ id }, index) => !ids?.includes(id, index + 1)
  );


  var debug = filtered?.filter(
    (x:any) =>
      x.name.toLowerCase().includes(barcode) ||
      x["barcode"].includes(barcode)
  );
  
  var cartProducts:any = filtered.map((product:any) => product.quantity>0 && product);


  React.useEffect(() => {
    let total: number = 0;
    filtered.forEach((singleTotal: BillingInterface) => {
      total = total + +singleTotal.sellingPrice * singleTotal.quantity;
    });

    if (discounAmount) {
      // eslint-disable-next-line
      {
        pin?.walletBalance > 0 &&
        pin?.walletBalance !== undefined &&
        pin?.walletBalance !== "NaN"
          ? setGrandTotal(
              total + pin?.walletBalance - (total * discounPersentage) / 100
            )
          : setGrandTotal(total - (total * discounPersentage) / 100);
      }
      // setGrandTotal(total -(total * discounPersentage) / 100);
      setDiscountAmount((total * discounPersentage) / 100);
      // setGrandTotal(total - discounAmount);
    } else {
      // eslint-disable-next-line
      {
        pin?.walletBalance > 0 &&
        pin?.walletBalance !== undefined &&
        pin?.walletBalance !== "NaN" && inputRef?.current?.value
          ? setGrandTotal(total - inputRef?.current?.value)
          : setGrandTotal(total);
      }
      // setGrandTotal(total);
    }
    setTotal(total);
    // eslint-disable-next-line
  }, [addedProduct, pin?.walletBalance,filtered]);

  const handleChangeDiscountAmount = (e: any) => {
    const amount = e.target.value;
    // eslint-disable-next-line
    {
      pin?.walletBalance > 0 &&
        pin?.walletBalance !== undefined &&
        pin?.walletBalance !== "NaN" &&
        setGrandTotal(total - amount - pin?.walletBalance);
    }

    if (amount > total) {
      setDiscountAmount(0);
      setDiscountPercentage(0);
      setGrandTotal(total);
    } else {
      setDiscountAmount(amount);
      setGrandTotal(total - amount);
      setDiscountPercentage((amount / total) * 100);
    }
    // setGrandTotal(total - amount - pin?.walletBalance);
    // }
  };

  const handleChangeDiscountPercentage = (e: any) => {
    const percentage = e.target.value;

    if (percentage > 100) {
      setDiscountPercentage(0);
    } else {
      setDiscountPercentage(percentage);
      setGrandTotal(total - (total * percentage) / 100);
      setDiscountAmount((total * percentage) / 100);
    }
  };

  const handleChnageCashReceive = (e: any) => {
    const receive = e.target.value;
    setCashReceive(receive);
    setCashReturn(receive - grandTotal);
  };

  const handleChnageCashReturn = (e: any) => {
    const returnCash = e.target.value;
    setCashReturn(returnCash);
  };
  
  var similarObjects:any = [];

  filtered.map((obj1:any) => {
    const obj2 = products.find((obj) => obj.product.id === obj1.id);
    if (obj2) {
      similarObjects.push(obj1);
    }
    return null;
  });

  var myFilterArray = cartProducts.filter(Boolean);

  const shouldScroll = filtered.length > 5;

  return (
    <Box>
      <Box>
        <Box sx={{ width: "65%" }}>
          <BarcodeInput
            filtered={filtered}
            value={barcode}
            onChange={handleChangeBarcode}
            name={"Billing"}
          />
        </Box>
        <Box sx={{ mt: 2, display: "flex", flexWrap: "wrap", width: "65%" }}>
            <>
              {barcode!=="" && debug?.map((card: BillingInterface) => (
                <ItemCard
                  key={card?.id}
                  id={card?.id}
                  productName={card.name}
                  quantity={card.quantity}
                  price={card.sellingPrice}
                  sellingPrice={card.sellingPrice}
                  increaseQuantity={() => increaseQuantity(card.id)}
                  decreaseQuantity={() => decreaseQuantity(card.id)}
                  weight={card?.weight}
                  originalQuantity={card.originalQuantity}
                 updateProductAmount={updateProductAmount}
                 product={card}
                />
              ))}
            </>
            <>
            {barcode==="" && myFilterArray.map((card:any) => (
             <ItemCard
             id={card.id}
             productName={card.name}
             quantity={card.quantity}
             price={card.sellingPrice}
             sellingPrice={card.sellingPrice}
             increaseQuantity={() => increaseQuantity(card.id)}
             decreaseQuantity={() => decreaseQuantity(card.id)}
             weight={card?.weight}
             originalQuantity={card.originalQuantity}
             updateProductAmount={updateProductAmount}
           />
      ))}
            </>
        </Box>
        {filtered.length ? (
          <Paper
            sx={{
              width: 400,
              position: "fixed",
              top: 0,
              bottom: 0,
              right: 0,
              zIndex: 99,
              padding: 2,
            }}
          >
            <Box sx={{ position: "relative", height: "97vh" }}>
              <div  style={{
            maxHeight: shouldScroll ? '200px' : 'unset',
            overflowY: shouldScroll ? 'scroll' : 'unset',
            scrollbarWidth: 'thin',
          }}>
              {filtered.length &&
                // eslint-disable-next-line
                filtered?.map((item: BillingInterface) => {
                  if (item.quantity > 0) {
                    return (
                      <>
                        {item.quantity && (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography>
                              {`${item.name} ${item.sellingPrice}x${item.quantity}`}{" "}
                              :-
                            </Typography>
                            <Typography>
                              Rs {parseInt(item.sellingPrice) * item.quantity}
                            </Typography>
                          </Box>
                        )}
                      </>
                    );
                  }
                })}
                </div>
              {filtered.length && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography component="div">Total:-</Typography>
                    <Typography component="div">Rs {total}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 2,
                      alignItems: "center",
                    }}
                  >
                    <Typography>Discount Amount:-</Typography>
                    <Typography component="div" className={classes.amount}>
                      <input
                        min="0"
                        name="discountAmount"
                        type="number"
                        value={Math.round(discounAmount)}
                        placeholder="amount"
                        onChange={handleChangeDiscountAmount}
                      />
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 2,
                      alignItems: "center",
                    }}
                  >
                    <Typography>Discount Percent:-</Typography>
                    <Typography component="div" className={classes.amount}>
                      <input
                        name="discountPercent"
                        type="text"
                        placeholder="%"
                        value={discounPersentage}
                        onChange={handleChangeDiscountPercentage}
                      />
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 2,
                      alignItems: "center",
                    }}
                  >
                    {/* <Typography>Wallet Used:-</Typography>
                    <Typography component="div" className={classes.amount}>
                      <input
                      ref={inputRef}
                        disabled
                        name="walletUsed"
                        type="number"
                        placeholder="%"
                        defaultValue={pin?.walletBalance>total?total:pin?.walletBalance}                      />
                    </Typography> */}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderTop: "2px solid black",
                      borderBottom: "2px solid black",
                      py: 1,
                      mt: 2,
                    }}
                  >
                    <Typography variant="h4">Grand Total :-</Typography>
                    <Typography component="div" className={classes.amount}>
                    {Math.round(grandTotal)}           
                    </Typography>{" "}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 2,
                    }}
                  >
                    <Typography>Cash Received :</Typography>
                    <Typography component="div" className={classes.amount}>
                      <input
                        type="text"
                        name="cashReceive"
                        onChange={handleChnageCashReceive}
                        value={cashReceive}
                      />
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 2,
                    }}
                  >
                    <Typography component="div">Return :</Typography>
                    <Typography component="div" className={classes.amount}>
                      <input
                        type="text"
                        name="cashReturn"
                        onChange={handleChnageCashReturn}
                        value={cashReturn}
                      />
                    </Typography>
                  </Box>
                </>
              )}
              {filtered?.map(
                (a: BillingInterface) =>
                  a.quantity > 0 && (
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        display: "flex",
                        mt: 2,
                      }}
                    >
                       <Typography
                        className={classes.cashCollectMethod}
                        onClick={() => {
                          setOpen("credit");
                        }}
                      >
                        Credit
                      </Typography>
                                            <Typography
                        className={classes.cashCollectMethod}
                        onClick={() => {
                          setIsonline(true);
                          setOpen("online");
                        }}
                      >
                        Online
                      </Typography>
                      {/* <Typography
                        className={classes.cashCollectMethod}
                        onClick={() => {
                          setOpen("card");
                        }}
                      >
                        Card
                      </Typography> */}
                      <Typography
                        className={classes.cashCollectMethod}
                        onClick={() => {
                          setOpen("card");
                        }}
                      >
                        Card/UPI
                        {/* UPI */}
                      </Typography>
                      <Typography
                        className={classes.cashCollectMethod}
                        onClick={() => {
                          setOpen("cash");
                        }}
                      >
                        Cash
                      </Typography>
                    </Box>
                  )
              )}
            </Box>
          </Paper>
        ) : (
          <></>
        )}
      </Box>
      <Modal
        header="Billing"
        children={
          isOnline ? (
            <Cash
              open={open}
              setOpen={setOpen}
              addedProduct={filtered}
              setAddedProduct={setAddedProduct}
              totalAndDiscounts={{
                grandTotal: grandTotal,
                discounPersentage,
                discounAmount,
              }}
              // barcode={barcode}
              setBarcode={setBarcode}
              setDiscountPercentage={setDiscountPercentage}
            setDiscountAmount={setDiscountAmount}
            />
          ) : (
            <Ofline
              open={open}
              setOpen={setOpen}
              setAddedProduct={setAddedProduct}
              addedProduct={filtered}
              totalAndDiscounts={{
                grandTotal: grandTotal,
                discounPersentage,
                discounAmount,
              }}
              setBarcode={setBarcode}
              wallet={inputRef?.current?.value}
              setDiscountPercentage={setDiscountPercentage}
              setDiscountAmount={setDiscountAmount}
            />
          )
        }
        handleClose={() => {
          setOpen("");
          setIsonline(false);
        }}
        open={open}
      />
    </Box>
  );
}