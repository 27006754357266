import { ProductInterface } from "../Interface/ProductsInterface";

function RtvDiscardBarcode(e:any,setBarcode:any,products:any,setAddedProduct:any,addedProduct:any) {
    setBarcode(e.target.value);
    let filteredNewArray: any = [];
    if (e.target.value.length > 0) {
      // eslint-disable-next-line
      const filteredProduct = products.filter((item: ProductInterface) => {
        if (item?.product?.barcode === e.target.value || item?.product?.barcode?.includes(e.target.value)===true || item?.product?.name.toLowerCase().replace(/ +/g, "") === e.target.value.toLowerCase().replace(/ +/g, "")|| item?.product?.name.toLowerCase().replace(/ +/g, "")?.includes(e.target.value)===true ) {
          const newObj = {
            id: item.id,
            name: item.product.name,
            quantity: 0,
            price: item.printedMrp,
            itemId:item?.product?.id,
            barcode:item?.product?.barcode
          };
          filteredNewArray = [...filteredNewArray, newObj];

          // filteredNewArray.push(newObj);
          // return newObj;
        }
        // return filteredNewArray
      });

      if (!filteredProduct.length) {
        // setAddedProduct([]);
        return;
      }
      setAddedProduct([...addedProduct,...filteredNewArray]);
    }
}

export default RtvDiscardBarcode