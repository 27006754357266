import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import LoadingComponent from "../../utility/LoadingComponent";
import { memo } from "react";

function DashboardCashout({
  returnData,
  channelSalesData,
  loading,
  digitalSalesData,
  cashSalesData,
  tnsAppData,
  creditData,
  returnTotal
}: any) {
  let today: string | Date = new Date();
  let dd: number | string = today.getDate();
  let mm: number | string = today.getMonth() + 1;
  let yyyy: number = today.getFullYear();

  today = mm + "/" + dd + "/" + yyyy;
  today = new Date(Date?.parse(today))?.toLocaleDateString();
  // const sum = returnData
  //   .map(
  //     (item: any) =>parseInt(item.returnamount))
  //   .reduce((prev: string, curr: string) => prev + curr, 0);

  return (
    <>
      {/* <h3>Cash Outs</h3> */}
      {loading === true ? (
        <LoadingComponent />
      ) : (
        <>
          <List>
            <ListItem divider>
              <ListItemText primary="Cash Sales: " /> {cashSalesData}
            </ListItem>
            <ListItem>
              <ListItemText primary="Digital Sales(UPI+Cards)" />
              {digitalSalesData}
            </ListItem>
            <Divider light />
            <ListItem>
              <ListItemText primary="Zomato/Swiggy/ONDC" />
              {channelSalesData}
            </ListItem>
            <Divider light />
            <ListItem>
              <ListItemText primary="Credit Sales: " />
              {creditData}
            </ListItem>
            <Divider light />
            <ListItem>
              <ListItemText primary="Tns-App" />
                      {tnsAppData}
            </ListItem>
            <Divider light />
            <ListItem>
              <ListItemText primary="Returns" />
              {returnTotal}
            </ListItem>
            <Divider light />
            <ListItem>
              <ListItemText primary="Total Sales" />
              {cashSalesData + digitalSalesData + channelSalesData + creditData + tnsAppData - returnTotal}
            </ListItem>
          </List>
          <Divider />
        </>
      )}
    </>
  );
}

export default memo(DashboardCashout);
