import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import { memo } from "react";
// import EditIcon from '@mui/icons-material/Edit';
// import SaveIcon from '@mui/icons-material/Save';

const useStyles = makeStyles(() => ({
  addButton: {
    background: "black !important",
    color: "white  !important",
    padding: "6px 35px !important",
    "&:hover": {
      background: "black !important",
    },
  },
  counterBtn: {
    background: "black !important",
    color: "white !important",
    borderRadius: "8px 0 0 8px !important",
    padding: "6px !important",
    "&:hover": {
      background: "black !important",
    },
  },
  counterBtnRemove: {
    background: "black !important",
    color: "white !important",
    borderRadius: "0 8px  8px 0 !important",
    padding: "6px !important",
    marginLeft: 0,
    "&:hover": {
      background: "black !important",
    },
  },
  count: {
    backgroundColor: "#dcdcdc",
    padding: "7px 10px",
  },
inputStyle : {
    padding: '10px',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    outline: 'none',
    transition: 'box-shadow 0.3s ease',
    width: '200px',
  },
}));

function BasicCard({
  id,
  productName,
  quantity,
  price,
  increaseQuantity,
  decreaseQuantity,
  weight,
  originalQuantity,
   product,
    updateProductAmount,
    totalQuantity,
    name
}: any) {
  const classes = useStyles();
  // const [isEditMode, setIsEditMode] = useState(false);

  // const handleEditClick = () => {
  //   setIsEditMode(true);
  // };

  // const [newAmount, setNewAmount] = useState(product?.sellingPrice);

  // const handleChange = (e:any) => {
  //   const inputValue = e.target.value;

  //   const sanitizedValue = inputValue.replace(/[^0-9]/g, '');

  //   setNewAmount(sanitizedValue);
  // };

  // const handleUpdate = () => {
  //   updateProductAmount(id,parseInt(newAmount));
  //   setIsEditMode(false);
  // };

  return (
    <Card sx={{ width: 250, m: 1 }} key={id}>
      <CardContent>
        <Typography variant="h5" color="text.secondary" gutterBottom>
          {productName}
        </Typography>
        <Typography component="div" sx={{ fontSize: 14 }}>
          Weight:- {weight}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          Price:- Rs.{price}
           {/* {!isEditMode ? (
            <>
             <IconButton onClick={handleEditClick}>
             <EditIcon />
           </IconButton>
        </>
      ) : (
        <div style={{position:"relative"}}>
      <input
       className={classes.inputStyle}
        value={newAmount} 
        type="text"
        onChange={handleChange}
        />
      <IconButton disabled={!newAmount} style={{position:"absolute",right:0}} onClick={handleUpdate}>
              <SaveIcon />
            </IconButton>
        </div>
      )} */}
        </Typography>
        {name!=="Discard" && (totalQuantity===undefined ? originalQuantity<0 ? 
        <Typography sx={{ mb: 1.5 }} color="red">
          In Stock: {originalQuantity}
        </Typography> :(
          <Typography sx={{ mb: 1.5 }} color="black">
          In Stock: {originalQuantity}
        </Typography>
        ):(<></>))}
        {name!=="Discard" && (
                  originalQuantity===undefined ?totalQuantity<0 ?<Typography sx={{ mb: 1.5 }} color="red">
                  In Stock: {totalQuantity}
                </Typography> :(
                  <Typography sx={{ mb: 1.5 }} color="black">
                  In Stock: {totalQuantity}
                </Typography>
                ):(<></>)
        )}
      </CardContent>
      <CardActions
        sx={{ alignItems: "center", display: "flex", justifyContent: "center" }}
      >
        {quantity ? (
          <div style={{ display: "flex" }}>
            <IconButton className={classes.counterBtn} onClick={increaseQuantity}>
              <AddCircleIcon />
            </IconButton>
            <span className={classes.count}>{quantity}</span>
            <IconButton
              sx={{ borderRadius: "0 8px 8px 0" }}
              onClick={decreaseQuantity}
              className={classes.counterBtnRemove}
            >
              <RemoveCircleIcon />
            </IconButton>
          </div>
        ) : (
          <Button onClick={increaseQuantity} className={classes.addButton}>
            Add
          </Button>
        )}
      </CardActions>
    </Card>
  );
}
export default memo(BasicCard)