import { Box } from '@mui/system'
import Header from '../header/index'
import { Outlet, useNavigate } from 'react-router-dom'
import BasicBreadcrumbs from '../breadcrum'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
// import { getAllOrdersTimeInterval } from "../../pages/onlineOrder/action";
import Utils from "../../utility";
// import navy_alarm from "./navy_alarmm.mp3";
import { useLocation } from 'react-router-dom'
import { creatOrderForPayment, getBillerId, getRoyalityData } from '../../pages/login/action'
import { useSelector } from 'react-redux'
import TransitionsModal from '../modal'
import { Table, TableHead, TableBody, TableRow, TableCell,Button } from '@mui/material';
import moment from 'moment';

const Index = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const {royalityData} = useSelector((state)=> state.loginReducer);

//   const [song] = useState(
//     new Audio(
//       navy_alarm
//     ),
//   )

// useEffect(() => {
//   const interval = setInterval(() => {
//     dispatch(
//       getAllOrdersTimeInterval(
//         (res, preCount) => {
//           if (res !== preCount) {
//             // Trigger alarm
//             song.play();
//             setTimeout(() => {
//               song.pause();
//             }, 5000);
//             // Update state with the new count
//             dispatch({
//               type: Utils.ActionName.ORDERS,
//               payload: {
//                 count: res,
//               },
//             });
//           }
//         },
//       )
//     );
//   },5 * 60 * 1000);

//   return () => clearInterval(interval);
//   // eslint-disable-next-line
// }, []);


  const { userId } = useSelector((state) => state.commonReducer);

  useEffect(() => {
    dispatch({
      type: Utils.ActionName.COMMON,
      payload: {
        userId: localStorage.getItem('id'),
        storeId: localStorage.getItem('storeId'),
        userType: localStorage.getItem('userType'),
        username: localStorage.getItem('username'),
        email: localStorage.getItem('email'),
      },
    })
    // eslint-disable-next-line
  }, [])
  // const getUserId = localStorage.getItem('id')

  useEffect(() => {
    if (userId) dispatch(getBillerId(userId))
    // eslint-disable-next-line
  }, [userId])

  const { storeId: getStoreId } = useSelector((state) => state.commonReducer);

  const navigate = useNavigate()


  const initializeRazorpay = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";

      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };

  const makePayment = async (amount) => {
    const res = await initializeRazorpay();

    if (!res) {
      alert("Razorpay SDK Failed to load");
      return;
    }

    dispatch(
      creatOrderForPayment(
        {
          invoiceNumber: "inoice_20",
          amount: amount,
          storeId: getStoreId,
          notes: "string",
        },
        (res) => {
          const options = {
            key: "rzp_test_hNVoPt48fZga5Q",
            amount: res?.amount,
            currency: "INR",
            name: "NEW SHOP CSTORE NETWORK PRIVATE LIMITED",
            description: "Test Transaction",
            image: "https://thenewshop.in/images/favicon.png",
            order_id: res?.id,
            handler: async function (response) {
              if (response.razorpay_order_id)
                dispatch(getRoyalityData(getStoreId,navigate));
              const data = {
                orderCreationId: res?.id,
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
              };
            },
            prefill: {
              // name: "Charak Almast",
              email: "productxventures@gmail.com",
              contact: "9650857683",
            },
            notes: {
              address: "",
            },
            theme: {
              color: "#0000",
            },
            method: {
              netbanking: true,
              card: true,
              wallet: false,
              upi: true,
              paylater: false,
              emi: false,
            },
            config: {
              display: {
                hide: [
                  {
                    method: "cardless_emi",
                  },
                  {
                    method: "paylater",
                  },
                  {
                    method: "wallet",
                  },
                ],
              },
            },
          };

          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
        }
      )
    );
  };
  const [openModal, setOpenModal] = useState(true);

  const isRoyaltyFinalDatePassed = (royaltyFinalDate) => {
    const currentDate = moment();
    const finalDate = moment(royaltyFinalDate);
    return currentDate.isAfter(finalDate);
  };

  const handleModalClose = () => {
    if (isRoyaltyFinalDatePassed(royalityData[0]?.royaltyFinalDate)) {
      setOpenModal(true);
    }else{
      setOpenModal(false)
    }
  };
  
  return (
    <>
    {royalityData.length > 0 && (
      <TransitionsModal
        header="Royalty Pending"
        children={
          openModal && (
            <>
            <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Store Name</TableCell>
          <TableCell>Royalty Pending</TableCell>
          <TableCell>Month / Year</TableCell>
          <TableCell>Invoice</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {royalityData.map((row) => (
          <TableRow key={row.id}>
            <TableCell>{row.id}</TableCell>
            <TableCell>{row.storename}</TableCell>
            <TableCell>{row.royaltyPayable}</TableCell>
            <TableCell>{row.month_mmm} / {row.year}</TableCell>
            <TableCell>
              <a href={row.invoice} target="_blank" rel="noopener noreferrer">
                View Invoice
              </a>
            </TableCell>
            <TableCell>
            <Button variant="outlined" onClick={() => makePayment(row?.royaltyPayable)}>Pay now</Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>  
          </>
                  ) 
        }
        handleClose={handleModalClose}
        open={openModal}
      /> 
    )}
    <Box>
      {location.pathname.includes('/billing') ||
      location.pathname.includes('/return') ||
      location.pathname.includes('/dashboard') ||
      // location.pathname.includes('/customers') ||
      location.pathname.includes('/credit') ||
      location.pathname.includes('/online-order') ||
      location.pathname.includes('/rtv') ||
      location.pathname.includes('/discard') ||
      location.pathname.includes('/cashout') ||
      location.pathname.includes('/audit') ||
      location.pathname.includes('#') ? (
        <Header />
      ) : (
        <>
          <h1 style={{ textAlign: 'center' }}>Delivery page</h1>
          <hr />
        </>
      )}
      <BasicBreadcrumbs />
      <Box sx={{ m: 2 }}>
        <Outlet />
      </Box>
    </Box>
    </>
  )
}
export default Index
