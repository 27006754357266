import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

type Props = {
  type: string;
  name: string;
  placeholder?: string;
  error?: any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  value?: string;
  disabled?: boolean;
  minWidth?: string;
  handleBlur?:(event: React.FocusEvent<HTMLInputElement>) => void;
};

export default function Index({
  type,
  name,
  error,
  onChange,
  label,
  value,
  disabled,
  minWidth,
}:Props) {
  return (
    <Box
      sx={{
        width: "100%",
        pr: minWidth?.5:2,
        maxWidth: "100%",
        minWidth: minWidth ? minWidth : "auto",
      }}
    >
      <TextField
        type={type}
        value={value}
        name={name}
        error={error}
        helperText={error}
        fullWidth
        disabled={disabled}
        label={label}
        id={name}
        size="small"
        onChange={onChange}
      />
    </Box>
  );
}
