import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import React, { useEffect } from 'react';
import { getAudit, getProductsStoreStocks, getStoreId } from './action';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import moment from "moment"
import AuditTable from './AuditTable';
import LoadingComponent from '../../utility/LoadingComponent';
import { Suspense } from 'react';
import { RootState } from '../../Interface/BillerIdInterface';

const AuditCashout = React.lazy(() => import('./AuditCashout'));
const StoreStocksAuditlazy = React.lazy(() => import('./StoreStocksAudit'));

const style = {
  width: '100%',
  bgcolor: 'background.paper',
};

export default function Audit() { 
const dispatch: any = useDispatch();
const {loading} = useSelector((state: any) => state.loginReducer);
let today:string | Date = new Date();
let dd:any = today.getDate();
let mm:number | string = today.getMonth() + 1;
let yyyy:number = today.getFullYear();
const { storeId: getStoreIdStore } = useSelector(
  (state: any) => state.commonReducer
);
// const getStoreIdStore = localStorage.getItem("storeId")

const {billerIdData} = useSelector((state: RootState) => state.loginReducer);

let finaldd;
let finalmm = "0" + mm;

if (dd < 10) {
  finaldd = '0' + dd;
    }else{
      finaldd = dd
    }
    
today = yyyy + '-' + finalmm + '-' + finaldd;

  useEffect(()=>{
    if(!getStoreIdStore) return;
    dispatch(getStoreId(getStoreIdStore))
    if(getStoreIdStore!==null && getStoreIdStore!==undefined && getStoreIdStore!==""){
      dispatch(getAudit(getStoreIdStore,today,today))
      dispatch(getProductsStoreStocks(getStoreIdStore))
    }
      // eslint-disable-next-line
  },[getStoreIdStore,billerIdData]);

  const CurrentTime = moment().format('DD/MM/YYYY HH:mm:ss')

  return (
    <>
          {loading===true ? <LoadingComponent />:(
            <>
    <Card sx={{ minWidth: 275 }}>
      <h1 style={{textAlign: "center"}}>{CurrentTime}</h1>
      <CardContent>
      <List sx={style} component="nav" aria-label="mailbox folders">
      <ListItemText primary="Cash Outs:" />
        <AuditTable />
        <Divider />
        <Suspense fallback={<LoadingComponent />}>
        <AuditCashout />
      </Suspense>
      </List>
      </CardContent>
    </Card>
    <Divider />
    <Card sx={{ minWidth: 275,marginTop:"20px" }}>
            <Suspense fallback={<LoadingComponent />}>
    <StoreStocksAuditlazy />
          </Suspense>
    </Card>
    </>
          )}
    </>
  );
}
