import Utils from '../../utility'

const initialState = {
  products: [],
  images: [],
  count: 0,
  order: 'ASC',
  sortField: 'name',
  offset: 1,
  take: 10,
  search: '',
  pin: [],
  loading: true,
}
export const productsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case `${Utils.ActionName.PRODUCTS}`:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return { ...state }
  }
}
