import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import {NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import {useState} from "react";
import TransitionsModal from "../modal";
import Logo from "./logo.jpeg";
import Typography from '@mui/material/Typography';
import { useDispatch } from "react-redux";
import { logoutRefreshToken } from "../../pages/login/action";

const useStyles = makeStyles(() => ({
  root: {},
  header: {
    alignItems: "baseline",
    background: "black",
    padding: "25px 0px 10px 0px",
    display: "flex",
  },
  activetab: {
    color: "black",
    background: "white",
    borderRadius: "8px 8px 0px 0px",
    padding: "10px 15px 13px",
    textDecoration: "none",
  },
  tab: {
    color: "white",
    testDecoration: "none",
    textDecoration: "none",
    padding: "10px 15px 13px",
  },
  logoContainer: {
    display: "inline-block",
    background: "none",
    border: "none",
    marginLeft: 10,
    width: 150,
    marginRight: 40,
    "&>img": {
      width: "100%",
      height: "40px",
      objectFit:"cover",
      marginTop:"5px"
    },
  },
}));

const Index = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  let navigate = useNavigate()
  const getUserName = localStorage.getItem("username")
  const dispatch: any = useDispatch();
  const refreshTokenData = localStorage.getItem("refreshToken")

  const handleClose = () => {
    setOpen(false);
  };

  const logout = () => {
    dispatch(logoutRefreshToken(refreshTokenData,navigate))
  }

  return (
    <Box className={classes.header}>
      <Box className={classes.logoContainer}>
        <img src={Logo} alt="Logo" loading="lazy" />
      </Box>
      <Box>
        <NavLink
          to="billing"
          className={({isActive})=> isActive ? classes.activetab : classes.tab}
        >
          Billing
        </NavLink>
        <NavLink
          to="/return"
          className={({isActive})=> isActive ? classes.activetab : classes.tab}
        >
          Return
        </NavLink>
        <NavLink
          to="/dashboard"
          className={({isActive})=> isActive ? classes.activetab : classes.tab}
        >
          Dashboard
        </NavLink>
        {/* <NavLink
          to="/customers"
          className={({isActive})=> isActive ? classes.activetab : classes.tab}
        >
          Customers
        </NavLink> */}
        <NavLink
          to="/credit"
          className={({isActive})=> isActive ? classes.activetab : classes.tab}
        >
          Credit
        </NavLink>
        <NavLink
          to="/online-order"
          className={({isActive})=> isActive ? classes.activetab : classes.tab}
        >
          Online order
        </NavLink>
        <NavLink
          to="/rtv"
          className={({isActive})=> isActive ? classes.activetab : classes.tab}
        >
          Rtv
        </NavLink>
     
        <NavLink
          to="/discard"
          className={({isActive})=> isActive ? classes.activetab : classes.tab}
        >
          Discard
        </NavLink>
        <NavLink
          to="/detail-page-for-delivery"
        >
        </NavLink>
        <NavLink
          to="/detail-page-for-delivery/:id"
        >
        </NavLink>
        <NavLink
          to="/:id"
        >
        </NavLink>
        <NavLink
          to="/cashout"
          className={({isActive})=> isActive ? classes.activetab : classes.tab}
        >
          Cashout
        </NavLink>
        {/* <NavLink
          to="/refresh"
          className={
            location.pathname.includes("/refresh")
              ? classes.activetab
              : classes.tab
          }
        >
          Refresh
        </NavLink> */}
        <NavLink
          to="/audit"
          className={({isActive})=> isActive ? classes.activetab : classes.tab}
        >
          Audit
        </NavLink>
          <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <>
          <NavLink to="#"
          className={
            location.pathname.includes("#")
              ? classes.activetab
              : classes.tab
          }
            {...bindTrigger(popupState)}>
                Support
          </NavLink>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Typography sx={{ p: 2 }}>
              <b>24 Hour Helpline Number</b>
              <br /> +91 83687 69360
            </Typography>
            <hr />
            <Typography
             sx={{ p: 2 }}
             style={{cursor:"pointer"}}
              onClick={()=>window.open(
                'https://support.thenewshop.in/support/login'
              )}
            >
              {' '}
              <b>  Report other issues</b>{' '}
            </Typography>
            <br />
          </Popover>
        </>
      )}
    </PopupState>
        <NavLink to="#"
          className={
            location.pathname.includes("#")
              ? classes.activetab
              : classes.tab
          }
          onClick={() => {
            setOpen(true);
          }}
        >
          Logout
        </NavLink>
        <TransitionsModal
        header="Logout"
        children={
          open && (
            <>
            <h2>Are You sure you want to logout?</h2>
            <Button variant="outlined" type="submit" onClick={logout}>
              Yes
          </Button>  
          <Button variant="outlined" sx={{ ml: 2 }} onClick={handleClose}>
              Cancel
          </Button>  
          </>
                  ) 
        }
        handleClose={() => {
          setOpen(false);
        }}
        open={open}
      />
      </Box>
      <p style={{color:"white",marginLeft:"auto"}}>Logged in as: <b style={{color:"white",right:10,marginRight:"20px"}}>{getUserName}</b></p>
    </Box>
  );
};
export default Index;
